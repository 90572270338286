import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
  ViewChild,
} from "@angular/core";
import { Store } from "@ngrx/store";
import {
  createSegmAnalysis,
  findingsUnsynced,
  IFinding,
  isAuthUserActive,
  Label,
  loadingMask,
  selectedLabels,
  selectmaskLoading,
  selectSegmentationTasks,
  syncAnalysis,
} from "@telespot/analysis-refactor/data-access";
import { AnalysisProtocolTask, IAssetROI, LabelUtils } from "@telespot/sdk";
import { Subject } from "rxjs";
import { pairwise, switchMap, takeUntil, throttleTime } from "rxjs/operators";
import { OpenseadragonComponent } from "../openseadragon/openseadragon.component";
import { MouseTracker, Point } from "openseadragon";
import {
  MasksPluginService,
  MaskViewerService,
  TOsdActiveAction,
  TSegmentationAction,
  ViewerService,
} from "@telespot/shared/viewers/data-access";
import { AuthService } from "@telespot/web-core";
import OpenSeadragon from "openseadragon";

@Component({
  selector: "ts-mask-viewer",
  templateUrl: "./mask-viewer.component.html",
  styleUrls: ["./mask-viewer.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MaskViewerComponent implements OnInit, OnChanges {
  @Input() osd: OpenseadragonComponent;
  @Input() opacity = 0.5;
  @Input() strokeWidth = 20;
  @Input() activeFinding: IFinding;
  @ViewChild("canvas", { static: true }) canvas: ElementRef<HTMLCanvasElement>;

  private _activeTasks: AnalysisProtocolTask[];
  private labelUtils = new LabelUtils();
  private _activeLabels: Label[];
  private _canEditMask: boolean;

  private _destroy$ = new Subject<void>();

  private _mousedown$ = new Subject<any>();
  private mousedown$ = this._mousedown$.asObservable();

  private _mousemove$ = new Subject<any>();
  private mousemove$ = this._mousemove$.asObservable();

  private _mouseleave$ = new Subject<any>();
  private mouseleave$ = this._mouseleave$.asObservable();

  public readonly selectedLabels$ = this.store.select(selectedLabels);
  public readonly canEditMask$ = this.store.select(
    isAuthUserActive(this._authService.currentUser.id)
  );

  public maskLoading$ = this.store.select(selectmaskLoading);

  ctx: CanvasRenderingContext2D;
  public _position: IAssetROI;
  public _spinnerPosition: IAssetROI;

  get position() {
    return { ...this._position };
  }
  get spinnerPosition() {
    return { ...this._spinnerPosition };
  }
  constructor(
    private store: Store,
    private _maskService: MasksPluginService,
    private _viewerService: ViewerService,
    private _authService: AuthService,
    private _maskViewerService: MaskViewerService
  ) {
    this.store
      .select(selectSegmentationTasks)
      .pipe(takeUntil(this._destroy$))
      .subscribe((tasks) => (this._activeTasks = tasks));
    this.store
      .select(selectedLabels)
      .pipe(takeUntil(this._destroy$))
      .subscribe((labels) => (this._activeLabels = labels));

    this.canEditMask$.subscribe((value) => {
      this._canEditMask = value;
    });

    this._maskViewerService.saveMaskOnLocalStorage
      .pipe(takeUntil(this._destroy$))
      .subscribe(async (payload) => {
        await this.saveMaskOnLocalStorage(payload.id);
        if (payload.save) this.store.dispatch(syncAnalysis());
      });
  }

  async ngOnInit() {
    await this._maskViewerService.initializeDB();
    this._position = {
      x: 0,
      y: 0,
      w: this.osd?._conf?.width,
      h: this.osd?._conf?.height,
    };
    this._spinnerPosition = {
      x: this.osd?._conf?.width / 2,
      y: this.osd?._conf?.height / 2,
      w: 1,
      h: 1,
    };
  }

  ngAfterViewInit() {
    this.ctx = this.canvas.nativeElement.getContext("2d");

    const originalWidth = this.osd?._conf?.width;
    const originalHeight = this.osd?._conf?.height;

    const scaleFactor = this.getScaleFactor(originalWidth, originalHeight);
    const scaledWidth = originalWidth * scaleFactor;
    const scaledHeight = originalHeight * scaleFactor;

    this.ctx.canvas.width = scaledWidth;
    this.ctx.canvas.height = scaledHeight;

    this.ctx.scale(scaleFactor, scaleFactor);
    this.ctx.lineWidth = this.strokeWidth;
    this.ctx.lineCap = "round";

    this.captureEvents(this.canvas.nativeElement);

    new MouseTracker({
      element: this.ctx.canvas,
      pressHandler: (e) => {
        if (
          this._viewerService.currentViewerMode === TOsdActiveAction.layers &&
          this._canEditMask
        ) {
          this._mousedown$.next({
            clientX: (e as any).originalEvent.clientX,
            clientY: (e as any).originalEvent.clientY,
          });
        }
      },
      releaseHandler: (e) => {
        if (
          this._viewerService.currentViewerMode === TOsdActiveAction.layers &&
          this._canEditMask
        ) {
          this._mouseleave$.next(true);
        }
      },
      moveHandler: (e) => {
        if (
          this._viewerService.currentViewerMode === TOsdActiveAction.layers &&
          this._canEditMask
        ) {
          this._mousemove$.next({
            clientX: (e as any).originalEvent.clientX,
            clientY: (e as any).originalEvent.clientY,
          });
        }
      },
      dragHandler: (e) => {
        if (this._viewerService.currentViewerMode === TOsdActiveAction.idle) {
          const viewportBounds = this.osd.viewer.viewport.getBounds();
          const canvasRect = this.ctx.canvas.getBoundingClientRect();

          const deltaX =
            (e as any).delta.x * this.osd.viewer.viewport.getZoom(true);
          const deltaY =
            (e as any).delta.y * this.osd.viewer.viewport.getZoom(true);
          const deltaXT = deltaX / canvasRect.width;
          const deltaYT = deltaY / canvasRect.height;

          const newBounds = new OpenSeadragon.Rect(
            viewportBounds.x - deltaXT,
            viewportBounds.y - deltaYT,
            viewportBounds.width,
            viewportBounds.height,
            viewportBounds.degrees
          );

          this.osd.viewer.viewport.fitBounds(newBounds);
        }
      },
    }).setTracking(true);

    if (this.activeFinding) {
      this.processActiveFinding();
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.activeFinding && changes.activeFinding.currentValue) {
      this.processActiveFinding();
    }
  }

  ngOnDestroy() {
    this._destroy$.next();
  }

  private captureEvents(canvasEl: HTMLCanvasElement) {
    let lastPosition: { x: number; y: number } = null;

    this.mousedown$
      .pipe(
        throttleTime(10),
        switchMap((e) => {
          lastPosition = null;

          return this.mousemove$.pipe(takeUntil(this.mouseleave$));
        }),
        pairwise()
      )
      .subscribe((res: [any, any]) => {
        if (!this._activeLabels || this._activeLabels.length < 1) return;
        if (!this.activeFinding) {
          this.store.dispatch(createSegmAnalysis());
        }
        if (this.activeFinding?.synced) {
          this.store.dispatch(
            findingsUnsynced({ finding: this.activeFinding })
          );
        }

        if (
          this._maskService.activeSegmentationMode === TSegmentationAction.edit
        ) {
          this.ctx.globalCompositeOperation = "destination-out";
          this.ctx.lineWidth = this.strokeWidth;
        } else {
          this.ctx.globalCompositeOperation = "source-over";
          this.ctx.lineWidth = this.strokeWidth;
          this.ctx.strokeStyle = this.getColor();
        }

        const coordprev = this._getCoords(res[0]);
        const coordcurr = this._getCoords(res[1]);

        const { prevPos, currentPos } = this._getPositions(
          coordprev,
          coordcurr
        );

        if (lastPosition) {
          this.drawOnCanvas(lastPosition, currentPos);
        }

        lastPosition = currentPos;
      });

    this.mouseleave$.subscribe(() => {
      lastPosition = null;
    });
  }

  private async processActiveFinding() {
    if (!this.activeFinding || !this.ctx) {
      console.log("Canvas context or activeFinding is missing");
      return;
    }

    this.clearCanvas();

    this.store.dispatch(loadingMask({ loading: true }));
    const src = await this._maskViewerService.fetchSegmentationMask(
      this.activeFinding
    );

    this.showSegmentationAnalysis(src);
    this.store.dispatch(loadingMask({ loading: false }));
  }

  getColor() {
    return this.labelUtils.getLabelColor(
      this._activeLabels.map((label) => label.uuid),
      true,
      this._activeTasks
    );
  }

  private _getCoords(event): { viewport: Point; image: Point } {
    //TODO: extract this functionality into utils
    const clientPos = new Point(event["clientX"], event["clientY"]);
    const viewportPos = this.osd.viewer.viewport.pointFromPixel(
      clientPos,
      true
    );
    return {
      viewport: viewportPos,
      image: this.osd.viewer.world
        .getItemAt(0)
        .viewportToImageCoordinates(viewportPos),
    };
  }

  private _getPositions(coordprev, coordcurr) {
    const prevPos = {
      x: coordprev.image.x,
      y: coordprev.image.y,
    };

    const currentPos = {
      x: coordcurr.image.x,
      y: coordcurr.image.y,
    };
    return { prevPos, currentPos };
  }

  private drawOnCanvas(
    prevPos: { x: number; y: number },
    currentPos: { x: number; y: number }
  ) {
    if (!this.ctx) {
      return;
    }
    this.ctx.beginPath();
    if (prevPos) {
      this.ctx.moveTo(prevPos.x, prevPos.y);
      this.ctx.lineTo(currentPos.x, currentPos.y);
      this.ctx.stroke();
    }
  }

  public showSegmentationAnalysis(src) {
    if (!src) return;
    const newIm = new Image();
    newIm.src = src;
    newIm.addEventListener("load", (e) => {
      this.ctx.drawImage(newIm, 0, 0);
    });
  }

  public async saveMaskOnLocalStorage(findingId) {
    const offscreenCanvas = document.createElement("canvas");
    offscreenCanvas.width = this.osd?._conf?.width;
    offscreenCanvas.height = this.osd?._conf?.height;

    const offscreenCtx = offscreenCanvas.getContext("2d");

    offscreenCtx.drawImage(
      this.ctx.canvas,
      0,
      0,
      this.ctx.canvas.width,
      this.ctx.canvas.height,
      0,
      0,
      offscreenCanvas.width,
      offscreenCanvas.height
    );

    const dataURL = offscreenCanvas.toDataURL("image/png");
    try {
      await this._maskViewerService.saveMask(findingId, dataURL);
      console.log(`Mask saved successfully for findingId: ${findingId}`);
    } catch (error) {
      console.error("Failed to save mask to IndexedDB:", error);
    }
  }

  public clearCanvas() {
    this.ctx.clearRect(0, 0, this.ctx.canvas.width, this.ctx.canvas.height);
  }

  private getScaleFactor(width: number, height: number): number {
    const maxDimension = Math.max(width, height);

    if (maxDimension > 15000) {
      return 0.1;
    } else if (maxDimension > 12000) {
      return 0.4;
    } else if (maxDimension > 8000) {
      return 0.6;
    } else if (maxDimension > 5000) {
      return 0.8;
    } else {
      return 1;
    }
  }
}
