import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "@telespot/shared/environment";
import { LoggerService } from "@telespot/shared/logger/feature/util";

interface IUploadResponse {
  file: string;
  links: {
    upload: {
      href: string;
    };
  };
}

@Injectable({
  providedIn: "root",
})
export class FindingFileService {
  constructor(private _http: HttpClient, private _logger: LoggerService) {}
  async upload(file: File, endpoint: "files/findings"): Promise<string> {
    this._logger.info(
      `[uploader-service] Uploading file ${file?.name} to /${endpoint}...`
    );
    try {
      const extension = file.name.substring(file.name.indexOf(".") + 1);
      const response = await this._http
        .post<IUploadResponse>(
          `${environment.api.url}/${endpoint}`,
          { ext: extension },
          {}
        )
        .toPromise();
      try {
        await this._http.put(response.links.upload.href, file).toPromise();
        return Promise.resolve(response.file);
      } catch (initError) {
        this._logger.fatal(
          `[uploader-service] Could not upload file: ${initError.message}`,
          initError
        );
        throw initError;
      }
    } catch (uploadError) {
      this._logger.fatal(
        `[uploader-service] Could not initiate upload: ${uploadError.message}`,
        uploadError
      );
      throw uploadError;
    }
  }

  async updateFinding(file: File, endpoint: "files/findings"): Promise<string> {
    this._logger.info(
      `[uploader-service] Uploading file ${file?.name} to /${endpoint}...`
    );
    try {
      const response = await this._http
        .put<IUploadResponse>(
          `${environment.api.url}/${endpoint}/${file?.name}`,
          { file: file.name },
          {}
        )
        .toPromise();
      try {
        await this._http.put(response.links.upload.href, file).toPromise();
        return Promise.resolve(response.file);
      } catch (initError) {
        this._logger.fatal(
          `[uploader-service] Could not upload file: ${initError.message}`,
          initError
        );
        throw initError;
      }
    } catch (uploadError) {
      this._logger.fatal(
        `[uploader-service] Could not initiate upload: ${uploadError.message}`,
        uploadError
      );
      throw uploadError;
    }
  }

  async getFinding(fileName: string, endpoint: "files/findings") {
    try {
      const headers = new HttpHeaders().set("Accept", "image/png,*/*");

      return this._http
        .get<File>(`${environment.api.url}/${endpoint}/${fileName}`, {
          headers,
          responseType: "blob" as "json",
        })
        .toPromise();
    } catch (err) {
      this._logger.error(
        "[ViewerConfigService] - Error retrieving masks",
        err.message
      );
      throw err;
    }
  }

  dataURItoBlob(dataURI) {
    const binary = atob(dataURI.split(",")[1]);
    const mimeString = dataURI.split(",")[0].split(":")[1].split(";")[0];
    const array = [];
    for (let i = 0; i < binary.length; i++) {
      array.push(binary.charCodeAt(i));
    }
    return new Blob([new Uint8Array(array)], { type: mimeString });
  }
}
