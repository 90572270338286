import { createFeatureSelector, createSelector } from "@ngrx/store";
import { ChatState, getChats, getMessages } from "./chat.reducer";

export const chatFeatureKey = "chat";

export const selectChatFeature =
  createFeatureSelector<ChatState>(chatFeatureKey);

export const selectAssistantsList = createSelector(
  selectChatFeature,
  (state) => state.assistants
);

export const selectActiveAssistant = createSelector(
  selectChatFeature,
  (state) =>
    state.activeAssistant
      ? state.assistants.find(
          (assistant) => assistant.id === state.activeAssistant
        )
      : null
);

export const selectActiveChat = createSelector(selectChatFeature, (state) => {
  return getChats(state).find((chat) => chat.active);
});

export const selectMessagesFromActiveChat = createSelector(
  selectChatFeature,
  selectActiveChat,
  (state, activeChat) => {
    const messagesFromActiveChat = activeChat
      ? getMessages(state)
          .filter((m) => m.chatId === activeChat.id)
          .sort((m1, m2) => m1.timestamp - m2.timestamp)
      : [];
    return messagesFromActiveChat;
  }
);

export const selectSendingMessage = createSelector(
  selectChatFeature,
  (state) => state.sending
);

export const selectLoading = createSelector(
  selectChatFeature,
  (state) => state.loading
);

export const selectCaseContext = createSelector(
  selectActiveAssistant,
  (activeAssistant) => activeAssistant?.context
);

export const selectContext = createSelector(
  selectChatFeature,
  (state) => state.context
);
