import { Component, OnDestroy } from "@angular/core";
import { Router } from "@angular/router";
import { AuthService } from "@telespot/web-core";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";

@Component({
  selector: "ts-chat-icon",
  templateUrl: "./chat-icon.component.html",
  styleUrls: ["./chat-icon.component.scss"],
})
export class ChatIconComponent implements OnDestroy {
  private _destroy$ = new Subject<void>();

  public chatIconVisible = true;
  public currentUser;
  public llmFeatureEnabled = false;
  public readonly currentUser$ = this.authService.currentUser$;
  public readonly currentOrganization$ = this.authService.currentOrganization$;

  constructor(private authService: AuthService, private router: Router) {
    this.router.events.pipe(takeUntil(this._destroy$)).subscribe((event) => {
      const currentRoute = this.router.url;
      this.chatIconVisible =
        this.llmFeatureEnabled && currentRoute.includes("login") === false;
    });

    this.currentOrganization$
      .pipe(takeUntil(this._destroy$))
      .subscribe((organization) => {
        this.llmFeatureEnabled = organization?.license?.features?.llm_chat
          ? true
          : false;
        this.chatIconVisible = this.llmFeatureEnabled;
      });

    this.currentUser$
      .pipe(takeUntil(this._destroy$))
      .subscribe((currentUser) => (this.currentUser = currentUser));
  }

  ngOnDestroy(): void {
    this._destroy$.next();
  }
}
