import { BreakpointObserver, Breakpoints } from "@angular/cdk/layout";
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  OnDestroy,
  OnInit,
} from "@angular/core";
import { MatDialog, MatDialogRef } from "@angular/material/dialog";
import { MatSnackBar } from "@angular/material/snack-bar";
import { ActivatedRoute, Router } from "@angular/router";
import { Store } from "@ngrx/store";
import {
  ConfirmationDialogComponent,
  SampleReviewDialogComponent,
  SampleFormComponent,
  SnackbarAlertComponent,
  TFileItem,
  TSampleGroup,
} from "@shared/ui";
import {
  CaseAnalysisService,
  caseSelectors,
  listSampleAnalysts,
  organizeSamples,
  sampleAnalysts,
  selectCases,
  selectCurrentWorkspace,
  selectdecrypted,
  selectWSEncrypted,
  setCurrentCaseId,
  setCurrWorkspace,
  setReferenceAnalyst,
  TiraspotUtils,
  toogleAnalysisMode,
} from "@telespot/analysis-refactor/data-access";
import {
  AuthService,
  DataService,
  FileUploaderService,
} from "@telespot/web-core";
import {
  Algorithms,
  AnalysisState,
  Asset,
  Case,
  CloudFunctions,
  Device,
  Sample,
  SampleAsset,
  StateName,
  User,
  Query,
  MethodType,
} from "@telespot/sdk";
import { CanComponentDeactivate } from "@telespot/shared/util";
import { BehaviorSubject, Observable, Subject } from "rxjs";
import {
  filter,
  map,
  shareReplay,
  switchMap,
  take,
  takeUntil,
  tap,
} from "rxjs/operators";

import { SampleAnalysisPreviewComponent } from "../sample-analysis-preview/sample-analysis-preview.component";
import { SampleReportPreviewComponent } from "../sample-report-preview/sample-report-preview.component";

import uid from "uid";
import { LoggerService } from "@telespot/shared/logger/feature/util";
import { setChatContext } from "@telespot/chat/data-access";

@Component({
  selector: "ts-case-analysis",
  templateUrl: `./case-analysis.component.html`,
  styleUrls: ["./case-analysis.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [CaseAnalysisService],
})
export class CaseAnalysisComponent
  implements OnInit, AfterViewInit, OnDestroy, CanComponentDeactivate
{
  /** PRIVATE VARIABLES */
  private _progress$ = new BehaviorSubject<{
    message?: string;
    progress?: number;
  }>({});
  private _caseValue: Case;
  private _devices: Device[] = [];
  private _loading$ = new BehaviorSubject<boolean>(true);
  private _destroy$ = new Subject<void>();
  private _tiraspot: boolean;
  private _dialogRef: MatDialogRef<any>;

  /** PUBLIC VARIABLES */
  public decryptedCase;
  public currentCase;
  public currentWorkspace;
  public _lastPagination$: {
    pageIndex: number;
    pageSize: number;
  };
  public wsEncrypted = false;
  public decrypted = false;
  public createdByUser = false;
  public loadingSamples$ = this._loading$;
  public getTiraspotAnswer = TiraspotUtils.getTiraspotAnswer;
  public readonly showMobileLayout: Observable<boolean> = this._breakpoint
    .observe([Breakpoints.Handset])
    .pipe(map((r) => r.matches));

  public readonly userState$: Observable<AnalysisState> =
    this._caseAnalysisService.currentUserState$;
  public readonly case$: Observable<Case> = this._caseAnalysisService.case$;

  public readonly isTiraspot$ = this.case$.pipe(
    map((_case) => _case?.workspace?.config?.environment === "tiraspot")
  );
  public readonly error$ = this._store.select(caseSelectors.selectCaseError);

  public actualCase;
  private _currCaseId;

  private loadingPopupDialog: MatDialogRef<ConfirmationDialogComponent, any>;

  public readonly sampleGroups$ = this._caseAnalysisService.samples$.pipe(
    switchMap(async (samples) => {
      this._loading$.next(true);
      // const _case = samples?.[0]?.sample.case;
      if (this._caseValue?.workspace?.config?.environment === "tiraspot") {
        samples = await Promise.all(
          samples.map(async (s) => {
            s.sample = await Sample.fetchSampleAssets(s.sample);
            return s;
          })
        );
      }

      const groups = organizeSamples(samples);
      // Sort by CaseType.methodTypes order
      return (this._caseValue?.caseType?.methodTypes ?? []).map(
        (methodType) =>
          groups.find(
            (sampleGroup) => sampleGroup.methodType.id === methodType.id
          ) || {
            methodType,
            samples: [],
          }
      );
    }),
    tap((_) => this._loading$.next(false)),
    takeUntil(this._destroy$),
    shareReplay({ bufferSize: 1, refCount: true })
  );

  constructor(
    private _router: Router,
    private _route: ActivatedRoute,
    private _authService: AuthService,
    private _breakpoint: BreakpointObserver,
    private _snackBar: MatSnackBar,
    private _dialog: MatDialog,
    private _caseAnalysisService: CaseAnalysisService,
    private _store: Store,
    private _uploader: FileUploaderService,
    private _logger: LoggerService,
    private _dataService: DataService,
    private _cdr: ChangeDetectorRef
  ) {
    const currCase = this._route.snapshot.data["case"];
    this._currCaseId = currCase.id;
    this._caseAnalysisService.setCase(currCase);
    this._store.dispatch(setCurrentCaseId({ caseId: currCase.id }));
    this._store.dispatch(setChatContext({ caseId: currCase.id }));
    this._store.dispatch(
      setCurrWorkspace({
        id: currCase.workspace.id,
      })
    );
    this.case$.pipe(takeUntil(this._destroy$)).subscribe((_case) => {
      this._caseValue = _case;
      this._tiraspot = _case?.workspace.config?.environment === "tiraspot";
    });
    this._route.queryParams
      .pipe(takeUntil(this._destroy$))
      .subscribe((params) => {
        this._lastPagination$ =
          params["pageIndex"] && params["pageSize"]
            ? { pageIndex: params["pageIndex"], pageSize: params["pageSize"] }
            : { pageIndex: 0, pageSize: 20 };
      });
    this.createdByUser =
      this._authService.currentUser.id ===
      this._route.parent.snapshot.data["case"].createdBy.id;

    this._store.select(selectdecrypted).subscribe((decrypt) => {
      this.decrypted = decrypt;
    });

    this._store
      .select(selectWSEncrypted)
      .pipe(takeUntil(this._destroy$))
      .subscribe((value) => (this.wsEncrypted = value));

    this._store
      .select(selectCurrentWorkspace)
      .pipe(takeUntil(this._destroy$))
      .pipe(filter((c) => c !== undefined))
      .subscribe((value) => (this.currentWorkspace = value.toJSON()));

    this.error$
      .pipe(
        filter((value) => !!value),
        takeUntil(this._destroy$)
      )
      .subscribe((error) => {
        this.loadingPopupDialog?.close();
        this.displaySnackMessage(error);
      });
  }

  /* Component Lifecycle Methods */

  ngOnInit(): void {
    this._store
      .select(selectCases)
      .pipe(takeUntil(this._destroy$))
      .pipe(filter((c) => c !== undefined))
      .subscribe((cases) => {
        const currentCase = (cases || []).find(
          (c) => c.objectId === this._currCaseId
        );

        if (!currentCase) return;

        this.currentCase = currentCase;
        this._cdr.detectChanges();
      });
  }
  async ngAfterViewInit() {
    await this.loadDevices();
  }

  ngOnDestroy() {
    this._store.dispatch(setCurrentCaseId({ caseId: null }));
    this._destroy$.next();
  }

  /* Component Guards Methods */

  canDeactivate() {
    // TODO
    return true;
  }

  /* Component Methods */

  private async loadDevices(): Promise<void> {
    const query = new Query(Device)
      .equalTo("organization", this._authService.currentOrganizationValue)
      .include(["deviceType"]);
    this._devices = await this._dataService.find(query);
  }

  private back() {
    this._router.navigate(
      ["/workspaces", this._caseValue.workspace.id, "cases"],
      {
        queryParams: {
          page: this._lastPagination$.pageIndex ?? 0,
          pageSize: this._lastPagination$.pageSize ?? 20,
        },
      }
    );
  }

  private async saveAssets(
    methodType: MethodType,
    sample: Sample,
    files: TFileItem[]
  ): Promise<Asset[]> {
    const newAssets: Asset[] = [];

    for (const [index, fileItem] of files.entries()) {
      this._progress$.next({
        ...this._progress$.value,
        progress: Math.ceil((index / files.length) * 100),
      });
      try {
        // File Upload
        const assetFile = await this._uploader.upload(fileItem.file, "files");
        // Map asset for each file
        const newAsset = new Asset({
          organization: this._caseValue.organization,
          device: sample.device,
          methodType: methodType,
          assetType: methodType.assetType,
          isMarked: fileItem.fav,
          assetFile,
        });

        const parseAsset = (await this._dataService.save(newAsset)) as Asset;

        newAssets.push(parseAsset);
      } catch (err) {
        this._logger.error(err);
        return Promise.reject(err.message);
      }
    }

    return newAssets;
  }

  private async saveSampleAssets(
    sample: Sample,
    assets: Asset[],
    tag: string
  ): Promise<void> {
    const sampleAssets = assets.map(
      (asset, index) =>
        new SampleAsset({
          asset: Asset.createWithoutData(asset.id),
          sample: sample,
          index,
          dateOfCapture: new Date(),
          tag,
        })
    );
    await this._dataService.save(sampleAssets);
  }

  /* UI Handlers Methods */

  public editUserState() {
    this._dialog
      .open(ConfirmationDialogComponent, {
        data: {
          title: "dialog.reset_user_analysis_state",
          cancelButtonText: "button.cancel",
          acceptButtonText: "button.accept",
        },
        hasBackdrop: true,
      })
      .afterClosed()
      .pipe(take(1), takeUntil(this._destroy$))
      .subscribe(async (answer) => {
        switch (answer) {
          case "accept":
            await this._caseAnalysisService.updateUserState(
              StateName.inProgress
            );
            this._cdr.detectChanges();
            break;
        }
      });
  }

  public async submitAnalysis({
    finishAnalysis = false,
    closeCase = false,
  }: {
    finishAnalysis: boolean;
    closeCase?: boolean;
  }) {
    await this._caseAnalysisService.updateUserState(
      finishAnalysis ? StateName.analyzed : StateName.inProgress
    );
    if (
      closeCase &&
      this._authService.currentUser.is(["admin", "analystmanager"])
    ) {
      try {
        await CloudFunctions.SetState(
          Case.createWithoutData(this._caseValue.id),
          StateName.analyzed,
          this._authService.currentUser
        );
      } catch (error) {
        console.error(error);
      }
      this._router.navigate(["reports", "cases", this._caseValue.id]);
    } else {
      this._snackBar.openFromComponent(SnackbarAlertComponent, {
        data: {
          icon: "ri-save-line",
          title: "info.saved",
          message: "",
        },
        duration: 1000,
      });
      this.back();
    }
  }

  /**
   * Display snack message with given title.
   *
   * @param title string for the snack message title
   * @returns a {@link MatSnackBarRef} reference to the opened snack bar
   */
  private displaySnackMessage(title: string) {
    const data = { title, icon: "ri-close-fill" };
    const snackBarRef = this._snackBar.openFromComponent(
      SnackbarAlertComponent,
      { duration: 3000, data }
    );

    return snackBarRef;
  }

  public showAnalysisPreview(sample: Sample, samples: Sample[] = []): void {
    this._dialog.open(SampleAnalysisPreviewComponent, {
      hasBackdrop: true,
      disableClose: true,
      width: "1008px",
      maxHeight: "90vh",
      data: {
        sample,
        samples,
      },
    });
  }

  public showReportPreview(sample: Sample): void {
    this._dialog.open(SampleReportPreviewComponent, {
      hasBackdrop: true,
      width: "1008px",
      // maxHeight: '90vh',
      data: {
        sample,
      },
    });
  }

  public goToAnalysis(sample: Sample, samples: Sample[] = []): void {
    this.isTiraspot$
      .pipe(take(1), takeUntil(this._destroy$))
      .subscribe((isTiraspot) => {
        if (isTiraspot) {
          this.showAnalysisPreview(sample, samples);
        } else {
          this.navigateToViewer(sample.id, false);
        }
      });
  }

  public navigateToViewer(sampleId: string, reviewed: boolean) {
    this._router.navigate(["samples", sampleId], {
      relativeTo: this._route,
    });
    this._store.dispatch(
      toogleAnalysisMode({ mode: reviewed ? "mosaic" : "normal" })
    );
  }

  public reviewSample(sample: Sample) {
    this._store.dispatch(listSampleAnalysts({ sample }));

    const onAnalystSelect = (ans) => ans !== "cancel";

    const currentUser = this._authService.currentUser;

    const data = {
      availableAnalysts: this._store.select(sampleAnalysts(sample.id)).pipe(
        filter((analysts) => !!analysts),
        take(1)
      ),
      sampleId: sample.id,
      currUser: {
        name: currentUser.username,
        entity: "User",
        id: currentUser.id,
      },
    };

    this._dialog
      .open(SampleReviewDialogComponent, { data })
      .afterClosed()
      .pipe(take(1), filter(onAnalystSelect), takeUntil(this._destroy$))
      .subscribe(async (analyst) => {
        if (!analyst) return;

        const analystSelected =
          analyst?.entity === "algorithm"
            ? (Algorithms.createWithoutData(analyst.id) as Algorithms)
            : (User.createWithoutData(analyst.id) as User);

        this._store.dispatch(
          setReferenceAnalyst({ analyst: analystSelected.toPointer() })
        );

        try {
          const { enableMosaicView } =
            await CloudFunctions.checkIfMosaicIsEnabled(data.sampleId);
          this.navigateToViewer(sample.id, enableMosaicView);
        } catch (error) {
          console.error(error);
        }
      });
  }

  public warningAI(sample: Sample): boolean {
    return sample.assets.some((asset) => TiraspotUtils.warningAI(asset));
  }

  public addSample(methodGroup: any) {
    const newSample = new Sample();
    newSample.methodType = methodGroup.methodType;
    newSample.name = `s${(methodGroup.samples.length + 1)
      .toString()
      .padStart(3, "0")}`;
    const sampleData: TSampleGroup = {
      sample: newSample,
      device: null,
      files: [],
    };
    this.editSample(sampleData);
  }

  public editSample(sampleData: TSampleGroup) {
    this._dialogRef = this._dialog.open(SampleFormComponent, {
      data: {
        sample: sampleData.sample,
        files: sampleData.files,
        devices: this._devices,
      },
      panelClass: "no-padding",
      disableClose: true,
      hasBackdrop: true,
    });

    this._dialogRef
      .afterClosed()
      .pipe(
        tap((_) => (this._dialogRef = undefined)),
        takeUntil(this._destroy$)
      )
      .subscribe(async (result) => {
        if (!result) return;

        this._dialogRef = this._dialog.open(ConfirmationDialogComponent, {
          hasBackdrop: true,
          disableClose: true,
          data: {
            title: "alert.saving",
            text: this._progress$.pipe(map((p) => p.message)),
            progress: this._progress$.pipe(map((p) => p.progress)),
            showLoadingSpinner: true,
          },
          width: "400px",
        });

        this._progress$.next({
          message: `Uploading sample ${result.sample.name}...`,
          progress: 0,
        });

        const isNew = result.sample.isNew();

        // Sample data
        if (isNew) {
          result.sample.case = this._caseValue;
          result.sample.organization = this._caseValue.organization;
        }
        try {
          // Save Sample
          const savedSample = isNew
            ? ((await this._dataService.save(result.sample)) as Sample)
            : sampleData.sample;

          // FIX - Assign dicom tag file by file
          const isDicom = result.files.every((fileItem) =>
            fileItem.file.name.endsWith(".dcm")
          );
          const tag: string = isDicom
            ? `dicom-${uid(10)}-${result.files.length}`
            : undefined;

          // Save Assets
          const savedAssets = await this.saveAssets(
            result.sample.methodType,
            savedSample,
            result.files
          );

          // Save SampleAssets
          await this.saveSampleAssets(savedSample, savedAssets, tag);

          this._dialogRef?.close();
          this._loading$.next(true);
          this._caseAnalysisService.setCase(this._caseValue);
        } catch (error) {
          this._logger.error(error);
          this._dialogRef?.close();
          this._loading$.next(true);
          this._caseAnalysisService.setCase(this._caseValue);
        }
      });
  }
}
