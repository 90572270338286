import { DOCUMENT } from "@angular/common";
import {
  ComponentFactory,
  ComponentFactoryResolver,
  ComponentRef,
  Directive,
  Host,
  Inject,
  Input,
  OnDestroy,
  OnInit,
  Renderer2,
  Self,
} from "@angular/core";
import { MatSnackBar } from "@angular/material/snack-bar";
import {
  IVideoROIFrame,
  ROI,
  RoiService,
  SampleAnalysisService,
} from "@telespot/analysis-refactor/data-access";
import {
  AssetUtils,
  IAssetROIWithModels,
  TRoiSelectionType,
} from "@telespot/sdk";
import { LoggerService } from "@telespot/shared/logger/feature/util";
import {
  TOsdActiveAction,
  ViewerService,
} from "@telespot/shared/viewers/data-access";
import { MouseTracker, Placement, Point, Rect } from "openseadragon";
import { BehaviorSubject, combineLatest, Subject } from "rxjs";
import {
  distinctUntilChanged,
  map,
  shareReplay,
  takeUntil,
  tap,
} from "rxjs/operators";

import { OpenseadragonComponent } from "../components/openseadragon/openseadragon.component";
import { ResizableBoxComponent } from "../components/resizable-box/resizable-box.component";
import { MIN_ROI_SIZE, RoisDirective } from "./rois.base.directive";
import { Platform } from "@angular/cdk/platform";

@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: "ts-openseadragon:[appOsdRois]",
  exportAs: "osdROIs",
})
export class OsdRoisDirective
  extends RoisDirective<OpenseadragonComponent>
  implements OnInit, OnDestroy
{
  @Input() set enableTagging(enabled: boolean) {
    this._enable$.next(enabled);
  }

  private readonly _toggleDrawingMode: boolean = !this._platform.isBrowser;

  private _enable$ = new BehaviorSubject<boolean>(true);
  private _destroyS$: Subject<void> = new Subject<void>();
  private _isViewMode = false;
  private _resizableBoxComponent: ResizableBoxComponent;
  private _selectionOverlay: HTMLElement;
  private _fallbackViewerMode: TOsdActiveAction;
  private _spaceKeyPressed = false;
  private _ctrlKeyPressed = false;
  private _shiftKeyPressed = false;
  private _nonPrimaryDown = false;
  private _dragPosition;

  public readonly visibleRois$ = combineLatest([
    this.viewerComponent.viewerReady$,
    this._roiService.microVisibleROIs$,
  ]).pipe(
    map(([ready, rois]) => (ready ? rois : [])),
    tap(() => {
      this.viewerComponent?.viewer?.canvas?.focus();
      if (!this._roiService.selectedROIS.length)
        this._resizableBoxComponent?.hide();
    }),
    shareReplay({ bufferSize: 1, refCount: true })
  );

  constructor(
    @Host() @Self() protected viewerComponent: OpenseadragonComponent,
    protected renderer: Renderer2,
    protected resolver: ComponentFactoryResolver,
    protected snackBar: MatSnackBar,
    protected _roiService: RoiService,
    protected _sampleAnalysisService: SampleAnalysisService,
    @Inject(DOCUMENT) private _document: Document,
    @Inject(Platform) private _platform: Platform,
    private _logger: LoggerService,
    private _viewerService: ViewerService
  ) {
    super(
      viewerComponent,
      renderer,
      resolver,
      snackBar,
      _roiService,
      _sampleAnalysisService
    );
  }

  ngOnInit() {
    combineLatest([
      this.viewerComponent.viewerReady$,
      this._sampleAnalysisService.isViewMode$.pipe(
        tap((value) => (this._isViewMode = value))
      ),
      this._enable$,
    ])
      .pipe(
        map(
          ([viewerReady, viewMode, enabled]) =>
            viewerReady && enabled && !viewMode
        ),
        distinctUntilChanged(),
        takeUntil(this._destroyS$)
      )
      .subscribe((value) => {
        value ? this._setupEventHandlers() : this._removeEventHandlers();
      });

    this._viewerService.activeDrawingMode$
      .pipe(takeUntil(this._destroyS$))
      .subscribe((mode) => {
        this._drawingMode = mode;
      });

    this._roiService.updateRoiSize(this._defaultROIsize);
  }

  ngOnDestroy(): void {
    this._destroyS$.next();
    this._removeEventHandlers();
  }

  protected _setupEventHandlers() {
    this._logger.debug(`⏹️ osd-rois directive: setup event handlers`);

    if (!this.viewerComponent.viewer) {
      this._logger.warn(`Viewer not available, addHandler() would fail`);
      return;
    }

    this.viewerComponent.viewer.addHandler(
      "canvas-click",
      this._hCanvasPrimaryClick
    );
    this.viewerComponent.viewer.addHandler(
      "canvas-nonprimary-press",
      this._hCanvasNoPrimaryClick
    );
    this.viewerComponent.viewer.addHandler(
      "canvas-nonprimary-release",
      this._hCanvasNoPrimaryRelease
    );
    this.viewerComponent.viewer.addHandler("canvas-drag", this._hCanvasDrag);
    this.viewerComponent.viewer.addHandler("page", this._hPageChange);
    this.viewerComponent.viewer.addHandler("canvas-key", this._osdKeyHandler);
    this.viewerComponent.viewer.addHandler("canvas-enter", this._hCanvasEnter);
    this.viewerComponent.viewer.addHandler("canvas-exit", this._hCanvasExit);

    this.viewerComponent.viewer.canvas.addEventListener(
      "keydown",
      this._keyDownHandler
    );
    this.viewerComponent.viewer.canvas.addEventListener(
      "keyup",
      this._keyUpHandler
    );

    new MouseTracker({
      element: this.viewerComponent.viewer.canvas,
      moveHandler: (event: any) => {
        if (this._nonPrimaryDown) {
          const delta = event.position.minus(this._dragPosition);
          this.viewerComponent.viewer.viewport.panBy(
            this.viewerComponent.viewer.viewport.deltaPointsFromPixels(
              delta.negate()
            )
          );
          this._dragPosition = event.position.clone();
        }
      },
    }).setTracking(true);
  }

  protected _removeEventHandlers() {
    this._logger.debug(`⏹️ osd-rois directive: remove event handlers`);

    if (!this.viewerComponent.viewer) {
      this._logger.debug(
        `⏹️ osd-rois directive: no need to remove handlers, there is no viewer`
      );
      return;
    }

    this.viewerComponent.viewer.removeHandler(
      "canvas-click",
      this._hCanvasPrimaryClick
    );
    this.viewerComponent.viewer.removeHandler(
      "canvas-nonprimary-press",
      this._hCanvasNoPrimaryClick
    );
    this.viewerComponent.viewer.removeHandler(
      "canvas-nonprimary-release",
      this._hCanvasNoPrimaryRelease
    );
    this.viewerComponent.viewer.removeHandler(
      "canvas-key",
      this._osdKeyHandler
    );
    this.viewerComponent.viewer.removeHandler("canvas-drag", this._hCanvasDrag);
    this.viewerComponent.viewer.removeHandler("page", this._hPageChange);
    this.viewerComponent.viewer.removeHandler(
      "canvas-enter",
      this._hCanvasEnter
    );
    this.viewerComponent.viewer.removeHandler("canvas-exit", this._hCanvasExit);

    this.viewerComponent.viewer.canvas.removeEventListener(
      "keydown",
      this._keyDownHandler
    );
    this.viewerComponent.viewer.canvas.removeEventListener(
      "keyup",
      this._keyUpHandler
    );
  }

  private _hCanvasEnter = (event) => {
    this.viewerComponent?.viewer?.canvas?.focus();
  };

  private _hCanvasExit = (event) => {
    if (this._nonPrimaryDown) {
      this._nonPrimaryDown = false;
      this._viewerService.toggleViewerMode(
        this._fallbackViewerMode ?? TOsdActiveAction.drawing
      );
    }
  };

  private _hPageChange = (event) => {
    // REVIEW
    this._resizableBoxComponent = undefined;
    this._getResizableBox();
  };

  private _hCanvasPrimaryClick = (event) => {
    if (this.activeMode === TOsdActiveAction.drawing) {
      this.removeOverlay();
      const pos = this._getCoords(event);
      let params;
      switch (this._drawingMode) {
        case TRoiSelectionType.boundingBox:
          params = {
            x: pos.image.x - this._defaultROIsize / 2,
            y: pos.image.y - this._defaultROIsize / 2,
            w: this._defaultROIsize,
            h: this._defaultROIsize,
          };
          break;
        case TRoiSelectionType.center:
          params = {
            x: pos.image.x,
            y: pos.image.y,
            w: 0,
            h: 0,
          };
          break;
      }
      const roi = AssetUtils.makeIntegerCoords(params);
      this.addNewROIToModel(roi);
    } else if (this.activeMode === TOsdActiveAction.selecting) {
      this._roiService.selectROIs(null, true);
      this._resizableBoxComponent?.hide();
    }
  };

  private _hCanvasNoPrimaryClick = (event) => {
    if (event.button === 1) {
      if (this._ctrlKeyPressed) return;
      this._nonPrimaryDown = true;
      this._dragPosition = event.position.clone();
      this._fallbackViewerMode = this._viewerService.currentViewerMode;
      this._viewerService.toggleViewerMode(TOsdActiveAction.idle);
    }
  };

  private _hCanvasNoPrimaryRelease = (event) => {
    if (event.button === 1) {
      if (this._ctrlKeyPressed) return;
      this._nonPrimaryDown = false;
      this._viewerService.toggleViewerMode(
        this._fallbackViewerMode ?? TOsdActiveAction.drawing
      );
    }
  };

  private _hCanvasDrag = (event) => {
    if (
      !(
        this.activeMode === TOsdActiveAction.drawing ||
        this.activeMode === TOsdActiveAction.selecting
      )
    )
      return;
    event.preventDefaultAction = true;

    const handlersSet =
      !!this.viewerComponent.viewer.getHandler("canvas-drag-end");
    if (!handlersSet) {
      this.viewerComponent.viewer.addHandler(
        "canvas-drag-end",
        this._hCanvasDragEnd
      );
      this.viewerComponent.viewer.removeHandler(
        "canvas-click",
        this._hCanvasPrimaryClick
      );
    }
    const pos = this._getCoords(event);

    if (this._tempROI === undefined) {
      this._tempROI = AssetUtils.makeIntegerCoords({
        x: pos.image.x,
        y: pos.image.y,
        w: 0,
        h: 0,
      });
      this._roiService.selectROIs(null, true);
    } else {
      if (
        this.activeMode === TOsdActiveAction.drawing &&
        this._drawingMode === TRoiSelectionType.center
      ) {
        this._tempROI.x = pos.image.x - 8;
        this._tempROI.y = pos.image.y - 8;
        this._tempROI.w = 16;
        this._tempROI.h = 16;
      } else {
        this._tempROI.w = pos.image.x - this._tempROI.x;
        this._tempROI.h = pos.image.y - this._tempROI.y;
      }
      this._tempROI = AssetUtils.makeIntegerCoords(this._tempROI);
    }
    const rect = this._getValidViewportRect(this._tempROI);

    if (this._selectionOverlay) {
      this.viewerComponent.viewer.updateOverlay(
        this._selectionOverlay,
        rect,
        Placement.LEFT
      );
    } else {
      this._selectionOverlay = this.renderer.createElement("div");
      this.renderer.setStyle(
        this._selectionOverlay,
        "border",
        "1px dashed white"
      );
      this.viewerComponent.viewer.addOverlay(this._selectionOverlay, rect);
    }
  };

  private _hCanvasDragEnd = (event) => {
    this.viewerComponent.viewer.removeHandler(
      "canvas-drag-end",
      this._hCanvasDragEnd
    );
    if (this._tempROI !== undefined) {
      this._tempROI = AssetUtils.makeIntegerCoords(
        AssetUtils.makePositiveCoords(this._tempROI)
      );

      if (
        this._tempROI.w > this._safeDragThreshold &&
        this._tempROI.h > this._safeDragThreshold
      ) {
        switch (this.activeMode) {
          case TOsdActiveAction.drawing:
            if (this._drawingMode === TRoiSelectionType.center) {
              this._tempROI.x += this._tempROI.w / 2;
              this._tempROI.y += this._tempROI.h / 2;
              this._tempROI.w = 0;
              this._tempROI.h = 0;
              this._tempROI = AssetUtils.makeIntegerCoords(
                AssetUtils.makePositiveCoords(this._tempROI)
              );
            }
            this.addNewROIToModel(this._tempROI);
            this._defaultROIsize = Math.max(
              MIN_ROI_SIZE,
              Math.floor(Math.sqrt(this._tempROI.w * this._tempROI.h))
            );
            this._roiService.updateRoiSize(this._defaultROIsize);

            break;
          case TOsdActiveAction.selecting:
            this._roiService.selectROIsInsideRegion(this._tempROI);
            break;
        }
      } else {
        this._logger.debug(`ROI skipped (too small)`);
        if (this.activeMode === TOsdActiveAction.drawing) {
          switch (this._drawingMode) {
            case TRoiSelectionType.center:
              this._roiService.addROIs([
                {
                  x: this._tempROI.x - this._tempROI.w / 2,
                  y: this._tempROI.y - this._tempROI.h / 2,
                  w: 0,
                  h: 0,
                },
              ]);
              break;
            case TRoiSelectionType.boundingBox:
              this._roiService.addROIs([
                {
                  x: this._tempROI.x - this._defaultROIsize / 2,
                  y: this._tempROI.y - this._defaultROIsize / 2,
                  w: this._defaultROIsize,
                  h: this._defaultROIsize,
                },
              ]);
              break;
          }
        }
      }
      this._tempROI = undefined;
    }
    this.removeOverlay();

    // This is to prevent that the drag end also fires the canvas-click handler
    this.viewerComponent.viewer.addOnceHandler("canvas-click", () => {
      const existingClickHandler =
        !!this.viewerComponent.viewer.getHandler("canvas-click");
      if (!existingClickHandler) {
        this.viewerComponent.viewer.addHandler(
          "canvas-click",
          this._hCanvasPrimaryClick
        );
      }
    });
  };

  private _osdKeyHandler = (e) => {
    if (this._shiftKeyPressed) return;
    const event = e.originalEvent;
    if (
      event.key === "ArrowLeft" ||
      event.key === "ArrowRight" ||
      event.key === "ArrowUp" ||
      event.key === "ArrowDown"
    ) {
      e.preventDefaultAction = true;
    }
  };

  private _keyDownHandler = (event) => {
    if (!this._toggleDrawingMode) {
      if (event.key === " " && !this._spaceKeyPressed) {
        if (this._ctrlKeyPressed) return;
        this._fallbackViewerMode = this._viewerService.currentViewerMode;
        this._viewerService.toggleViewerMode(TOsdActiveAction.idle);
        this._spaceKeyPressed = true;
      } else if (
        (event.key === "Control" || event.key === "Meta") &&
        !this._ctrlKeyPressed
      ) {
        this._fallbackViewerMode = this._viewerService.currentViewerMode;
        if (this._nonPrimaryDown || this._spaceKeyPressed) return;
        if (this.activeMode === TOsdActiveAction.selecting) return;
        this._viewerService.toggleViewerMode(TOsdActiveAction.selecting);
        this._ctrlKeyPressed = true;
      } else if (event.key === "Shift") {
        this._shiftKeyPressed = true;
      } else if (event.key === "ArrowRight") {
        if (!this._shiftKeyPressed) this._sampleAnalysisService.nextAsset();
        event.preventDefault();
        event.stopPropagation();
      } else if (event.key === "ArrowLeft") {
        if (!this._shiftKeyPressed) this._sampleAnalysisService.previousAsset();
        event.preventDefault();
        event.stopPropagation();
      } else if (event.key === "f" || event.key === "F") {
        event.preventDefault();
        event.stopPropagation();
      } else if (event.key === "r" || event.key === "R") {
        event.preventDefault();
        event.stopPropagation();
      } else if (event.key === "s" || event.key === "S") {
        if (this._ctrlKeyPressed) {
          this._sampleAnalysisService.saveAnalysis();
          event.preventDefault();
          event.stopPropagation();
        }
      } else if (event.key === "Delete" || event.key === "Backspace") {
        this._roiService.removeSelectedROIs();
      }
    }
  };

  private _keyUpHandler = (event) => {
    const activeNodeName = this._document.activeElement.nodeName.toLowerCase();
    const activeNodeType = this._document.activeElement.getAttribute("type");
    if (
      activeNodeName === "textarea" ||
      (activeNodeName === "input" && activeNodeType === "text")
    )
      return;
    switch (event.key) {
      case " ":
        if (this._ctrlKeyPressed) return;
        this._viewerService.toggleViewerMode(
          this._fallbackViewerMode ?? TOsdActiveAction.drawing
        );
        this._spaceKeyPressed = false;
        break;
      case "Control":
      case "Meta":
        if (this._nonPrimaryDown || this._spaceKeyPressed) return;
        if (this._fallbackViewerMode === TOsdActiveAction.selecting) return;
        this._roiService.selectROIs(null, true);
        this._resizableBoxComponent?.hide();
        this._ctrlKeyPressed = false;
        this._viewerService.toggleViewerMode(
          this._fallbackViewerMode ?? TOsdActiveAction.drawing
        );
        break;
      case "Shift":
        this._shiftKeyPressed = false;
        break;
    }
  };

  private _getCoords(event): { viewport: Point; image: Point } {
    const clientPos =
      event["position"] || new Point(event["layerX"], event["layerY"]);
    const viewportPos = this.viewerComponent.viewer.viewport.pointFromPixel(
      clientPos,
      true
    );
    return {
      viewport: viewportPos,
      image: this.viewerComponent.viewer.world
        .getItemAt(0)
        .viewportToImageCoordinates(viewportPos),
    };
  }

  private _getResizableBox() {
    if (!this._resizableBoxComponent) {
      // Cleanup old nodes
      this._document.querySelectorAll("#box").forEach((e) => e.remove());
      const factory: ComponentFactory<ResizableBoxComponent> =
        this.resolver.resolveComponentFactory(ResizableBoxComponent);
      const componentRef: ComponentRef<ResizableBoxComponent> =
        this.viewerComponent.container.createComponent(factory);
      this._resizableBoxComponent = componentRef.instance;
      this._resizableBoxComponent.hide();
    }
    this._resizableBoxComponent.osd = this.viewerComponent.viewer;
    this._resizableBoxComponent.roiPositionChanged
      .pipe(takeUntil(this._destroyS$))
      .subscribe((pos) => {
        this._roiService.updateROIposition(pos);
      });
  }

  /**
   * Retrieves a valid viewport rect with **positive** coordinates
   *
   * @param {IAssetROIWithModels} roi
   * @returns
   * @memberof OsdRoisDirective
   */
  private _getValidViewportRect(
    roi: IAssetROIWithModels | Partial<IVideoROIFrame>
  ) {
    const img = this.viewerComponent.viewer.world.getItemAt(0);
    const correctedROI = {
      x: roi.w < 0 ? roi.x + roi.w : roi.x,
      y: roi.h < 0 ? roi.y + roi.h : roi.y,
      w: roi.w < 0 ? -roi.w : roi.w,
      h: roi.h < 0 ? -roi.h : roi.h,
    };
    return img.imageToViewportRectangle(
      new Rect(correctedROI.x, correctedROI.y, correctedROI.w, correctedROI.h)
    );
  }

  public isSelected(roi: ROI): boolean {
    return roi.selected;
  }

  public handleOverlayClick(event, roi: ROI) {
    if (this._isViewMode) return;
    if (this.activeMode !== TOsdActiveAction.selecting) return;
    const selected = this.selectROI(roi, true);
    this._getResizableBox();
    if (selected.includes(roi)) {
      if (this._resizableBoxComponent) {
        this._resizableBoxComponent.roi = roi;
        if (!!roi.w && !!roi.h) this._resizableBoxComponent.show();
      }
    }

    event.preventDefaultAction = true;
  }

  public removeOverlay() {
    if (this._selectionOverlay) {
      this._logger.debug("remove selection overlay!");
      this.viewerComponent.viewer.removeOverlay(this._selectionOverlay);
      this._selectionOverlay.remove();
      this._selectionOverlay = undefined;
    }
  }
}
