import { EventEmitter, Injectable } from "@angular/core";

import { FindingFileService } from "@telespot/web-core";

export interface SaveLocalStorageDetails {
  save: boolean;
  id: string;
}
@Injectable({
  providedIn: "root",
})
export class MaskViewerService {
  private dbName = "MasksDB";
  private storeName = "Masks";
  saveMaskOnLocalStorage = new EventEmitter<SaveLocalStorageDetails>();

  constructor(private _findingFileService: FindingFileService) {}

  public async fetchSegmentationMask(finding) {
    const maskFromStorage = await this.loadMask(finding.id);

    if (
      finding.synced &&
      (!maskFromStorage || maskFromStorage.startsWith("blob"))
    ) {
      if (finding.data === undefined) return;
      const blob = await this._findingFileService.getFinding(
        finding.data as string,
        "files/findings"
      );
      const src = URL.createObjectURL(blob);
      try {
        await this.saveMask(finding.id, src);
        console.log(`Mask saved successfully for findingId: ${finding.id}`);
      } catch (error) {
        console.error("Failed to save mask to IndexedDB:", error);
      }

      return src;
    }

    if (finding.id.startsWith("copy:") && maskFromStorage === null) {
      const key = finding.id.substring(
        finding.id.indexOf(":") + 1,
        finding.id.indexOf("/")
      );
      const copiedMask = await this.loadMask(key);
      try {
        await this.saveMask(finding.id, copiedMask);
        console.log(`Mask saved successfully for findingId: ${finding.id}`);
      } catch (error) {
        console.error("Failed to save mask to IndexedDB:", error);
      }

      return copiedMask;
    }

    return maskFromStorage;
  }

  public saveMaskOnStorageIfNeeded(
    hasSegmentationTasks: boolean,
    activeFinding,
    isAuthUser: boolean
  ) {
    if (hasSegmentationTasks && activeFinding !== undefined && isAuthUser) {
      this.saveMaskOnLocalStorage.emit({
        save: false,
        id: activeFinding?.id,
      });
    }
  }

  public initializeDB(): Promise<IDBDatabase> {
    return new Promise((resolve, reject) => {
      const request = indexedDB.open(this.dbName, 1);
      request.onupgradeneeded = (event: any) => {
        const db = event.target.result;
        if (!db.objectStoreNames.contains(this.storeName)) {
          console.log("Upgrading IndexedDB...");

          db.createObjectStore(this.storeName, { keyPath: "id" });
          resolve(db);
        }
      };

      request.onsuccess = (event: any) => {
        const db = event.target.result;

        if (db.objectStoreNames.contains(this.storeName)) {
          console.log("IndexedDB initialized successfully.");
          resolve(db);
        }
      };

      request.onerror = (event) => {
        console.error("IndexedDB initialization error:", event);
        reject(event);
      };

      request.onblocked = () => {
        console.warn(
          "IndexedDB initialization is blocked. Please close other connections."
        );
        reject(new Error("IndexedDB initialization blocked"));
      };
    });
  }

  public saveMask(id: string, dataURL: string): Promise<void> {
    return new Promise((resolve, reject) => {
      const request = indexedDB.open(this.dbName);

      request.onsuccess = (event: any) => {
        const db = event.target.result;
        const transaction = db.transaction(this.storeName, "readwrite");
        const store = transaction.objectStore(this.storeName);

        const maskData = { id, dataURL };
        const addRequest = store.put(maskData);

        addRequest.onsuccess = () => {
          console.log(`Mask saved with id: ${id}`);
          resolve();
        };

        addRequest.onerror = (err) => {
          console.error("Error saving mask:", err);
          reject(err);
        };
      };

      request.onerror = (err) => {
        console.error("Error opening IndexedDB:", err);
        reject(err);
      };
    });
  }

  public loadMask(id: string): Promise<string | null> {
    return new Promise((resolve, reject) => {
      const request = indexedDB.open(this.dbName);

      request.onsuccess = (event: any) => {
        const db = event.target.result;
        const transaction = db.transaction(this.storeName, "readonly");
        const store = transaction.objectStore(this.storeName);

        const getRequest = store.get(id);

        getRequest.onsuccess = () => {
          if (getRequest.result) {
            resolve(getRequest.result.dataURL);
          } else {
            console.log(`No mask found with id: ${id}`);
            resolve(null);
          }
        };

        getRequest.onerror = (err) => {
          console.error("Error loading mask:", err);
          resolve(null);
        };
      };

      request.onerror = (err) => {
        console.error("Error opening IndexedDB:", err);
        resolve(null);
      };
    });
  }

  public deleteMask(id: string): Promise<void> {
    return new Promise((resolve, reject) => {
      const request = indexedDB.open(this.dbName);

      request.onsuccess = (event: any) => {
        const db = event.target.result;
        const transaction = db.transaction(this.storeName, "readwrite");
        const store = transaction.objectStore(this.storeName);

        const deleteRequest = store.delete(id);

        deleteRequest.onsuccess = () => {
          console.log(`Mask deleted with id: ${id}`);
          resolve();
        };

        deleteRequest.onerror = (err) => {
          console.error("Error deleting mask:", err);
          reject(err);
        };
      };

      request.onerror = (err) => {
        console.error("Error opening IndexedDB:", err);
        reject(err);
      };
    });
  }
  public async deleteAllMasks(): Promise<void> {
    return new Promise((resolve, reject) => {
      const request = indexedDB.open(this.dbName);

      request.onsuccess = (event: any) => {
        const db = event.target.result;
        const transaction = db.transaction(this.storeName, "readwrite");
        const store = transaction.objectStore(this.storeName);

        const clearRequest = store.clear();

        clearRequest.onsuccess = () => {
          console.log(`All masks data cleared from store: ${this.storeName}`);
          resolve();
        };

        clearRequest.onerror = (err) => {
          console.error("Error clearing data from store:", err);
          reject(err);
        };

        transaction.oncomplete = () => {
          db.close();
        };
      };

      request.onerror = (err) => {
        console.error("Error opening database:", err);
        reject(err);
      };
    });
  }
}
