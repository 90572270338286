import { IEnvironment } from "./i-environment";

export const environment: IEnvironment = {
  production: false,
  api: {
    url: "https://api.dev.telespot.org/v1",
    appId: "WB0vIskNlTWvNDy7NCbhLpfbQTGdeds2",
    jsKey: "jsKey",
    liveQueryServerURL: "ws://alb-compute-dev.dev.telespot.org/v1/parse",
    wsURL: "wss://ws.dev.telespot.org/v1",
  },
  logging: {
    level: 1,
  },
  serverLogging: {
    url: "https://n72yjdj98g.execute-api.us-east-1.amazonaws.com/live/logs",
    apiKey: "jQEYBF0AoKr7gKfpIzFy5zXAdHuiKAxaHAlfcd6e",
    appId: "4e0cf95b-c961-425b-95d1-edf8be50d3d2",
    level: 7,
  },
  // eslint-disable-next-line @typescript-eslint/no-var-requires
  VERSION: require("../../../../../../../package.json").version,
  external_links: {
    support: {
      en: "https://docs.google.com/forms/d/e/1FAIpQLSe8eHPZHdW_VhNE2k0aOYfuGNbbijFKYAQdmF6H_zJOIYzntw/viewform?usp=pp_url&entry.54953920=__username__&entry.263402721=Digital+product+(app+or+web+platform)&entry.329953787=TeleSpot&entry.303001118=__OS__&entry.9952778=__browser__",
      es: "https://docs.google.com/forms/d/e/1FAIpQLSd2JXeMHTQs9kuearhLC_9GzSC6ockfViJFHqm8Bxsi50_UHw/viewform?usp=pp_url&entry.2078446507=__username__&entry.1176919279=Producto+digital+(aplicaci%C3%B3n+o+plataforma+web)&entry.515917864=Plataforma+TeleSpot&entry.128607970=__OS__&entry.962894644=__browser__",
      pt: "https://docs.google.com/forms/d/e/1FAIpQLSdnIpt9r2KKdPGxud5v97PJ-aeXScIyL-Hg64c_qMc_G8D6ZA/viewform?usp=pp_url&entry.1795727691=__username__&entry.1576041462=Produto+digital+(aplica%C3%A7%C3%A3o+ou+plataforma)&entry.1885793429=Plataforma+TeleSpot&entry.1057982736=__OS__&entry.1691540948=__browser__",
    },
    ai: {
      analyze: "https://api.dev.telespot.org/v1/ai/invocations",
    },
  },
  enable_logs: true,
  show_development_watermark: true,
  recorder: {
    apiKey: "null",
    enable: false,
    loggingLevel: 3,
    disable_spotlab_users: false,
  },
  firebase: {
    apiKey: "AIzaSyDaUj137qIJ77VRWrE3I8a9bQU1JO6Cg6s",
    authDomain: "telespot-web-b455f.firebaseapp.com",
    projectId: "telespot-web-b455f",
    storageBucket: "telespot-web-b455f.appspot.com",
    messagingSenderId: "433153992437",
    appId: "1:433153992437:web:444db9dd54da87991307bc",
    measurementId: "G-QMLWC1WS2H",
  },
  serviceWorker: true,
  llm_api: {
    apiKey: "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",
    url: "https://api.openai.com/v1/chat/completions",
  },
};
