import { BreakpointObserver } from "@angular/cdk/layout";
import { Platform } from "@angular/cdk/platform";
import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  Inject,
  OnDestroy,
  OnInit,
  Renderer2,
  TemplateRef,
  ViewChild,
} from "@angular/core";
import { MatDialog, MatDialogRef } from "@angular/material/dialog";
import { MatRadioChange } from "@angular/material/radio";
import { MatSnackBar } from "@angular/material/snack-bar";
import { ActivatedRoute, Router } from "@angular/router";
import {
  ConfirmationDialogComponent,
  HeaderFooterService,
  PRESETS,
  SnackbarAlertComponent,
} from "@shared/ui";
import {
  AiAnalysisService,
  analysisState,
  CaseAnalysisService,
  exitAnalysis,
  loadAssetAnalysis,
  loadProtocol,
  loadSampleAnalysis,
  RoiService,
  SampleAnalysisService,
  selectContext,
  selectActiveSample,
  selectAsset,
  selectMultipleSelection,
  selectShowPinnedOnly,
  togglePinnedOptionsOnly,
  toggleMultiSelect,
  isFav,
  isBookmarkedAsset$,
  resetProtocolState,
  ISampleItem,
  selectRefStripElements,
  copyAnalysis,
  hasCopyAnalysis,
  hasActiveAssetAnalysis,
  Mode,
  switchMode,
  selectMode,
  selectHasROITasks,
  selectAssetAndIndex,
  clearViewerCtxState,
  setSelectedSample,
  bookmarkAsset,
  setFavAnalysis,
  progressPercent,
  sampleReady,
  numAssets,
  extractAssetsInfo,
  selectHasSegmentationTasks,
  nextCase,
  previousCase,
  nextSample,
  previousSample,
  clearHistoryState,
  setInitialCounterFilters,
  setAsset,
  loadAssetIndex,
  selectProtocolLoading,
  setViewerType,
  viewerType,
  toogleAnalysisMode,
  mosaicMode,
  mosaicView,
  loadMosaic,
  refStripItemsReady,
  selectTaskGroups,
  fetchingCropsProgress,
  clearSampleCounterState,
  clearSyncState,
  labelTrackingReady,
  hasUnlabeledRois,
  loadStatsForMosaicReview,
  ROI,
  hspan,
  setCurrentCaseId,
  clearMasksFromStorage,
  getActiveSegmentationFinding,
} from "@telespot/analysis-refactor/data-access";
import { AuthService, MosaicService } from "@telespot/web-core";
import { AnalysisState, Asset, Sample, StateName } from "@telespot/sdk";
import { LoggerService } from "@telespot/shared/logger/feature/util";
import { CanComponentDeactivate } from "@telespot/shared/util";
import {
  TOsdActiveAction,
  ViewerService,
} from "@telespot/shared/viewers/data-access";
import { BehaviorSubject, combineLatest, Observable, of, Subject } from "rxjs";
import {
  distinctUntilChanged,
  filter,
  map,
  mergeMap,
  share,
  shareReplay,
  startWith,
  switchMap,
  take,
  takeUntil,
  tap,
} from "rxjs/operators";

import { DownloadAssetsDialogComponent } from "../dialogs/download-assets-dialog/download-assets-dialog.component";
import { SecondOpinionDialogComponent } from "../dialogs/second-opinion-dialog/second-opinion-dialog.component";
import { MobileAnalysisPanelComponent } from "../mobile-analysis-panel/mobile-analysis-panel.component";
import { SampleAnalysisPanelComponent } from "../sample-analysis-panel/sample-analysis-panel.component";
import { Store } from "@ngrx/store";
import { SampleAnalysisToolsComponent } from "../sample-analysis-tools/sample-analysis-tools.component";
import { UnsavedChangesDialogComponent } from "../dialogs/unsaved-changes-dialog/unsaved-changes-dialog.component";
import { SampleAnalysisPanelMosaicsComponent } from "../sample-analysis-panel-mosaics/sample-analysis-panel-mosaics.component";
import { setChatContext } from "@telespot/chat/data-access";

@Component({
  selector: "ts-sample-analysis",
  templateUrl: "./sample-analysis.component.html",
  styleUrls: ["./sample-analysis.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    SampleAnalysisService,
    AiAnalysisService,
    RoiService,
    ViewerService,
  ],
})
export class SampleAnalysisComponent
  implements OnInit, OnDestroy, CanComponentDeactivate
{
  @ViewChild(SampleAnalysisPanelComponent)
  analysisPanel: SampleAnalysisPanelComponent;
  @ViewChild(MobileAnalysisPanelComponent)
  mobileAnalysisPanel: MobileAnalysisPanelComponent;
  @ViewChild(SampleAnalysisPanelMosaicsComponent)
  mosaicAnalysisPanel: SampleAnalysisPanelMosaicsComponent;
  @ViewChild(SampleAnalysisToolsComponent)
  analysisTools: SampleAnalysisToolsComponent;
  @ViewChild("reviewAnalysisDialogTpl", { read: TemplateRef })
  reviewAnalysisDialogTpl;
  @ViewChild("scrollableWrapper") scrollableWrapper!: ElementRef;

  public readonly selectedAsset$ = this.store.select(selectAsset);
  public readonly refStripItems$ = this.store.select(selectRefStripElements);
  public readonly bookmarked$ = this.store.select(isBookmarkedAsset$);
  public readonly currentMode$ = this.store.select(selectMode);
  public readonly hasUnsavedData$ = this._sampleAnalysisService.dirty$;
  public readonly sample$ = this.store.select(sampleReady);
  public readonly sampleItemsReady$ = this.store.select(refStripItemsReady);
  public readonly numAssets$ = this.store.select(numAssets);
  public readonly nextCase$ = this.store.select(nextCase);
  public readonly previousCase$ = this.store.select(previousCase);
  public readonly nextSample$ = this.store.select(nextSample);
  public readonly previousSample$ = this.store.select(previousSample);
  public readonly analysisContext$ = this.store.select(selectContext);
  public readonly showPinned$ = this.store.select(selectShowPinnedOnly);
  public readonly availableROItasks$ = this.store.select(selectHasROITasks);
  public readonly isFav$ = this.store.select(isFav);
  public readonly copyAllowed$ = this.store.select(hasCopyAnalysis);
  public readonly viewerType$ = this.store.select(viewerType);
  public readonly mosaicMode$ = this.store.select(mosaicMode);
  public readonly mosaicView$ = this.store.select(mosaicView);
  public readonly protocol$ = this.store.select(selectTaskGroups);
  public readonly labelTrackingReady$ = this.store.select(labelTrackingReady);
  public readonly hasUnlabeledRois$ = this.store.select(hasUnlabeledRois);
  public readonly hspan$ = this.store.select(hspan);
  public activeSegmentationFinding$ = this.store.select(
    getActiveSegmentationFinding
  );
  public readonly hasActiveAssetAnalysis$ = this.store.select(
    hasActiveAssetAnalysis
  );
  public readonly availableSegmTasks$ = this.store.select(
    selectHasSegmentationTasks
  );
  public readonly multiModelSelection$ = this.store.select(
    selectMultipleSelection
  );
  public readonly viewingReviewedAnalyst$ = this.store
    .select(analysisState)
    .pipe(
      map((state) => state?.user?.id !== this._authService.currentUser?.id)
    );
  public readonly reviewedAnalysis$ = this.store
    .select(analysisState)
    .pipe(filter((a) => a?.user?.id !== this._authService.currentUser.id));

  public readonly activeViewerMode$ = this._viewerService.activeViewerMode$;
  public readonly isViewMode$ = this._sampleAnalysisService.isViewMode$.pipe(
    distinctUntilChanged(),
    share()
  );
  public readonly loadingProgress$ = this.store
    .select(progressPercent)
    .pipe(startWith(0));

  public readonly mosaicLoadingProgress$ = this.store
    .select(fetchingCropsProgress)
    .pipe(startWith(0));

  public readonly layout$: Observable<"mobile" | "web"> = combineLatest([
    this._breakpoint.observe(["(min-width: 600px)"]),
  ]).pipe(
    map(([breakpoint]) => breakpoint.matches),
    startWith(!(this._platform.ANDROID || this._platform.IOS)),
    map<boolean, "web" | "mobile">((web) => (web ? "web" : "mobile")),
    shareReplay({ bufferSize: 1, refCount: true })
  );
  public readonly processingAI$ = this._aiService.prioritizationStatus$.pipe(
    startWith("idle")
  );

  public stitchingScans: string;
  public switchingAnalystPanelVisible = false;
  public noShowCopyDialog = false;
  public showFabs = true;

  private _sample: Sample;
  private _refStripItems: ISampleItem[];
  private _selectedAsset: Asset;
  private _currentMode: Mode;
  private _currentAnalysisState: AnalysisState;
  private _dialogRef: MatDialogRef<any>;
  private _destroy$ = new Subject();
  private _fallbackViewerMode: TOsdActiveAction;
  private _mosaicModeActive: boolean;
  private _hasUnlabeledRois: boolean;

  private _showMobileAnalysisPanelB$ = new BehaviorSubject<boolean>(true);
  private _secondOpinionGuest$ = new BehaviorSubject<boolean>(false);
  private _analysisState$ = this.store.select(analysisState);
  private _loadingProtocol$ = this.store.select(selectProtocolLoading);

  get sampleAnalysisProgress() {
    return this._sampleAnalysisService.progressTracker?.percentage;
  }
  get showMobileAnalysisPanel$() {
    return this._showMobileAnalysisPanelB$.asObservable();
  }

  constructor(
    private store: Store,
    private _aiService: AiAnalysisService,
    private _sampleAnalysisService: SampleAnalysisService,
    private _caseAnalysisService: CaseAnalysisService,
    private _authService: AuthService,
    private _breakpoint: BreakpointObserver,
    private _dialog: MatDialog,
    private _headerFooterService: HeaderFooterService,
    private _roiService: RoiService,
    private _router: Router,
    private _route: ActivatedRoute,
    private _snackBar: MatSnackBar,
    private _logger: LoggerService,
    private _viewerService: ViewerService,
    private renderer: Renderer2,
    private _mosaicService: MosaicService,
    @Inject(Platform) private _platform: Platform
  ) {
    const sampleId = this._route.snapshot.paramMap.get("sampleid");
    const queryParams = { ...this._route.snapshot.queryParams };
    if (queryParams?.counterFilter?.length > 0) {
      this.store.dispatch(
        setInitialCounterFilters({
          counterFilters: Array.isArray(queryParams?.counterFilter)
            ? queryParams?.counterFilter
            : [queryParams?.counterFilter],
          sampleId,
        })
      );
    }

    this._dialogRef
      ?.afterClosed()
      .pipe(takeUntil(this._destroy$))
      .subscribe(async (result) => {
        switch (result) {
          case "accept":
            await (
              this.analysisPanel || this.mobileAnalysisPanel
            ).saveAnalysis();
            if (!this._authService.currentUser.isGuest) {
              this._caseAnalysisService.setCase(
                this._route.snapshot.data["case"]
              );
              this._caseAnalysisService.updateUserState(StateName.inProgress);
            }
            this._authService.logout();
            this._router.navigate(["thanks"]);
            break;
          case "cancel":
          default:
            this._dialogRef = undefined;
        }
      });

    this.refStripItems$
      .pipe(takeUntil(this._destroy$))
      .subscribe((refStripItems) => {
        this._refStripItems = refStripItems;
      });

    this.hasUnlabeledRois$
      .pipe(takeUntil(this._destroy$))
      .subscribe((hasUnlabeledRois) => {
        this._hasUnlabeledRois = hasUnlabeledRois;
      });

    this.selectedAsset$.pipe(takeUntil(this._destroy$)).subscribe((asset) => {
      this._selectedAsset = asset;
      if (asset?.assetFile.includes("scan-")) {
        this.stitchingScans = asset.assetFile.substring(
          asset.assetFile.indexOf("-", asset.assetFile.indexOf("-") + 1) + 1,
          asset.assetFile.lastIndexOf(".")
        );
      }
    });

    combineLatest([this._analysisState$, this._loadingProtocol$])
      .pipe(
        filter((analysisState) => analysisState !== undefined),
        distinctUntilChanged(),
        takeUntil(this._destroy$)
      )
      .subscribe(([analysisState, protocolLoading]) => {
        this._currentAnalysisState = analysisState;
        const createdBy = (
          analysisState
            ? analysisState.user ?? analysisState.algorithm
            : this._authService.currentUser
        ).toPointer();

        if (!protocolLoading) {
          this.store.dispatch(loadSampleAnalysis({ sampleId, createdBy }));
        }
      });

    this.store
      .select(selectAssetAndIndex)
      .pipe(takeUntil(this._destroy$))
      .subscribe(({ asset, index }) => {
        if (asset) {
          this.selectAssetWithIndex(index, asset);
        }
      });

    this._headerFooterService.toggleHeaderAndFooter(false);

    combineLatest([
      this._analysisState$,
      this._sampleAnalysisService.selectedAsset$,
      this._loadingProtocol$,
      this.mosaicMode$,
    ])
      .pipe(
        distinctUntilChanged(
          (
            [prevAnalysisState, prevAsset, prevProtocolLoading, prevMosaicMode],
            [currAnalysisState, currAsset, currProtocolLoading, currMosaicMode]
          ) => {
            const sameValues =
              prevAnalysisState?.id === currAnalysisState?.id &&
              prevAsset?.id === currAsset?.id &&
              prevProtocolLoading === currProtocolLoading;

            const isMosaicModeChanged =
              prevMosaicMode === true && currMosaicMode === false;

            return sameValues && !isMosaicModeChanged;
          }
        ),
        takeUntil(this._destroy$)
      )
      .subscribe(([analysisState, asset, protocolLoading]) => {
        if (analysisState && asset && !protocolLoading) {
          this._secondOpinionGuest$.next(
            this._authService.currentUser.isOnlyGuest
          );

          const assetId = asset?.id;
          const createdBy = (
            analysisState.user ?? analysisState.algorithm
          ).toPointer();
          const sampleId = analysisState?.sample?.id;

          this.store.dispatch(
            loadAssetAnalysis({ createdBy, sampleId, assetId })
          );
        }
      });

    combineLatest([
      this._sampleAnalysisService.sample$,
      this._route.queryParamMap.pipe(
        map((params) => params.get("historyId")),
        distinctUntilChanged()
      ),
    ])
      .pipe(takeUntil(this._destroy$))
      .subscribe(([_sample, _historyId]) => {
        if (_sample)
          this._roiService.updateAnalysisState(_historyId, _sample.id);
        if (!_historyId) {
          this.toggleViewerMode(TOsdActiveAction.drawing);
        } else {
          this.toggleViewerMode(TOsdActiveAction.idle);
        }
      });

    this._roiService.selectedRois$
      .pipe(
        filter((rois) => !!rois?.length),
        takeUntil(this._destroy$)
      )
      .subscribe((_) => {
        this.toggleViewerMode(TOsdActiveAction.selecting);
      });

    this._roiService.selectedModelsEvent$
      .pipe(takeUntil(this._destroy$))
      .subscribe(() => this.toggleViewerMode(TOsdActiveAction.drawing));

    combineLatest([
      this.store.select(selectActiveSample),
      this.store.select(analysisState),
    ])
      .pipe(takeUntil(this._destroy$))
      .subscribe(([_sample, _analysisState]) => {
        if (_sample && _analysisState) {
          this._roiService.loadStats(
            _sample,
            _analysisState?.user ?? _analysisState?.algorithm,
            _analysisState.id
          );
        }
      });

    combineLatest([
      this.mosaicMode$,
      this.sampleItemsReady$,
      this._loadingProtocol$,
    ])
      .pipe(takeUntil(this._destroy$))
      .subscribe(([mode, sampleReady, loadingProtocol]) => {
        this._mosaicModeActive = mode;
        if (mode && sampleReady && !loadingProtocol) {
          this._logger.debug(`Mosaic viewer mode is active`);
          this.store.dispatch(loadStatsForMosaicReview());
        }
      });

    combineLatest([this.mosaicMode$, this.labelTrackingReady$])
      .pipe(
        takeUntil(this._destroy$),
        filter(
          ([mosaicMode, labelTrackingReady]) => mosaicMode && labelTrackingReady
        )
      )
      .subscribe(() => {
        this.store.dispatch(loadMosaic());
      });

    this.reviewedAnalysis$
      .pipe(takeUntil(this._destroy$))
      .subscribe((_) => (this.switchingAnalystPanelVisible = true));
    this.currentMode$
      .pipe(takeUntil(this._destroy$))
      .subscribe((mode) => (this._currentMode = mode));
  }

  ngOnInit(): void {
    this._route.params.subscribe((routeParams) => {
      this.store.dispatch(clearHistoryState());
      this.store.dispatch(clearViewerCtxState());
      this.store.dispatch(exitAnalysis());
      this.store.dispatch(resetProtocolState());
      const caseId = this._route.snapshot.paramMap.get("caseid");
      this.store.dispatch(setCurrentCaseId({ caseId }));
      this.store.dispatch(setChatContext({ caseId }));

      const sampleId = routeParams.sampleid;

      this.initSample(sampleId);
    });

    this._route.queryParams.subscribe((params) => {
      if (params.asset) {
        this._sampleAnalysisService
          .fetchAsset(params.asset)
          .subscribe((asset) => {
            this._logger.debug(`asset ${asset?.id}`);
            this.store.dispatch(setAsset({ asset }));
          });
      } else {
        this.store.dispatch(loadAssetIndex({ index: 0 }));
      }
    });
  }

  ngOnDestroy(): void {
    this._secondOpinionGuest$.next(false);
    this._sampleAnalysisService.progressTracker?.saveProgress();
    this._headerFooterService.toggleHeaderAndFooter(true);
    this.store.dispatch(setCurrentCaseId({ caseId: null }));
    this.store.dispatch(clearViewerCtxState());
    this.store.dispatch(clearSampleCounterState());
    this.store.dispatch(exitAnalysis());
    this.store.dispatch(clearMasksFromStorage());
    this.store.dispatch(resetProtocolState());
    this.store.dispatch(clearSyncState());
    this._destroy$.next();
  }

  canDeactivate(): Observable<boolean> {
    const analysisPanel =
      this.analysisPanel ||
      this.mobileAnalysisPanel ||
      this.mosaicAnalysisPanel;
    if (this._secondOpinionGuest$.value) return of(true);

    const dirty = this._sampleAnalysisService.isDirty;

    return (
      this._hasUnlabeledRois ? this._handleUnlabeledRoisDialog() : of("accept")
    ).pipe(
      switchMap((result) => {
        if (result !== "accept" && result !== "default") return of(false);

        return dirty
          ? this._handleExitWithoutSavingDialog(analysisPanel)
          : this._handleMosaicModeCheck();
      }),
      takeUntil(this._destroy$)
    );
  }

  private _handleMosaicModeCheck(): Observable<boolean> {
    if (this._mosaicModeActive) {
      this._dialogRef = this._dialog.open(
        ConfirmationDialogComponent,
        PRESETS.DIALOG_REVIEW_SAMPLE
      );
      return this._dialogRef.afterClosed().pipe(
        take(1),
        tap(() => (this._dialogRef = undefined)),
        map((exit: any) => {
          switch (exit) {
            case "accept":
              this.store.dispatch(toogleAnalysisMode({ mode: "normal" }));
              return false;
            case "cancel":
            default:
              return true;
          }
        })
      );
    }
    return of(true);
  }

  private _handleExitWithoutSavingDialog(
    analysisPanel: any
  ): Observable<boolean> {
    this._dialogRef =
      this._dialog.openDialogs.length === 0
        ? this._dialog.open(
            ConfirmationDialogComponent,
            PRESETS.DIALOG_EXIT_WITHOUT_SAVING
          )
        : this._dialog.openDialogs[0];

    return this._dialogRef.afterClosed().pipe(
      take(1),
      tap(() => (this._dialogRef = undefined)),
      switchMap(async (save: any) => {
        switch (save) {
          case "discard":
            return of(true);
          case "cancel":
            return of(false);
          case "accept":
            analysisPanel.saveAnalysis();
            if (!this._authService.currentUser.isGuest)
              this._caseAnalysisService.updateUserState(StateName.inProgress);

            return this.hasUnsavedData$.pipe(
              filter((hasUnsavedData) => hasUnsavedData === false),
              map(() => true),
              take(1)
            );
          default:
            return of(true);
        }
      }),
      mergeMap((value) => (typeof value === "boolean" ? of(value) : value))
    );
  }
  private _handleUnlabeledRoisDialog(): Observable<string> {
    return this._dialog
      .open(ConfirmationDialogComponent, PRESETS.DIALOG_UNLABELED_ROIS)
      .afterClosed()
      .pipe(
        take(1) // Ensure the observable completes after one emission
      );
  }
  public removeSelectedROIs = () => this._roiService.removeSelectedROIs();

  public async initSample(sampleId: string) {
    const _sample = Sample.createWithoutData(sampleId);
    await _sample.fetchWithInclude([
      "methodType.assetType",
      "case.workspace",
      "case.caseType",
      "methodType.analysisTypes",
    ]);
    this._sample = _sample;
    this.store.dispatch(setSelectedSample({ selectedSample: _sample }));
    this.store.dispatch(
      setViewerType({ viewerType: _sample.methodType.assetType.type })
    );
    this.store.dispatch(
      extractAssetsInfo({ selectedSample: _sample, skip: 0 })
    );
    this.store.dispatch(loadProtocol({ sample: _sample }));
    this._logger.info(`Loading sample "${_sample?.name ?? _sample.id}"...`);
  }

  public getSample() {
    return this._sample;
  }

  public async toggleBookmark(asset: Asset) {
    const analysisState = this._currentAnalysisState;
    analysisState.assetBookmark =
      !analysisState?.assetBookmark ||
      analysisState?.assetBookmark?.id !== asset?.id
        ? asset
        : null;
    this.store.dispatch(bookmarkAsset({ assetId: asset?.id, analysisState }));
    await this._sampleAnalysisService.saveAnalysisState(analysisState);
  }

  public async selectAssetWithIndex(index: number, asset: Asset) {
    try {
      try {
        if (asset === undefined) {
          //REVIEW: This is needed for videoROIS
          asset = await this.store
            .select(selectAsset)
            .pipe(take(1))
            .toPromise();
        }
        await this._aiService.setAssetForAnalysis(asset);
      } catch (err) {
        this._logger.warn(`Could not set asset for AI analysis`, err.message);
      }
    } catch (err) {
      this._logger.fatal(`Could not load image`, err.message);
    }
  }

  public saveAndExit() {
    try {
      const analysisPanel =
        this.analysisPanel ||
        this.mobileAnalysisPanel ||
        this.mosaicAnalysisPanel;
      if (!analysisPanel) return;
      if (this._secondOpinionGuest$.value) {
        this._dialogRef =
          this._dialogRef ||
          this._dialog.open(
            ConfirmationDialogComponent,
            PRESETS.DIALOG_SAVING_SECOND_OPINION
          );
      } else {
        analysisPanel.saveAnalysis();
        if (!this._authService.currentUser.isOnlyGuest) {
          this._caseAnalysisService.setCase(this._route.snapshot.data["case"]);
          this._caseAnalysisService.updateUserState(StateName.inProgress);
        }
      }
      this._logger.info("Sample analysis saved");
    } catch (err) {
      this._snackBar.openFromComponent(SnackbarAlertComponent, {
        duration: 1500,
        data: {
          title: `Saving failed`,
          icon: "ri-close-fill",
          message: `Error ocurred`,
        },
      });
      this._logger.fatal(err);
    }
  }

  public requestSecondOpinion() {
    this._dialogRef =
      this._dialogRef ||
      this._dialog.open(SecondOpinionDialogComponent, {
        hasBackdrop: true,
        disableClose: true,
        width: "800px",
        maxHeight: "90vh",
        data: {
          assets: this._sample.assets.map((a, index) => {
            return { id: a.id, thumbnail: a.thumbnail, selected: false, index };
          }),
          sampleId: this._sample.id,
          caseId: this._sample.case.id,
        },
      });
    // this._removeKeyboardListeners();
    this._dialogRef
      .afterClosed()
      .pipe(takeUntil(this._destroy$))
      .subscribe((result) => {
        // this._initKeyboardListeners();
        this._dialogRef = undefined;
      });
  }

  public downloadFile(imageFormat: boolean) {
    this._dialogRef =
      this._dialogRef ||
      this._dialog.open(DownloadAssetsDialogComponent, {
        hasBackdrop: true,
        disableClose: true,
        width: "800px",
        data: {
          assets: this._refStripItems.map((item) => {
            const asset = Asset.createWithoutData(item.assetId);
            asset.assetFile = item.assetFile;
            return {
              id: item.assetId,
              thumbnail: item.thumbnail,
              selected: false,
              index: item.assetIndex,
              assetFile: asset.assetFileURL,
              data: { hspan: item?.hspan },
            };
          }),
          sampleName: this._sample.name,
          imageFormat,
        },
      });
    // this._removeKeyboardListeners();
    this._dialogRef
      .afterClosed()
      .pipe(takeUntil(this._destroy$))
      .subscribe((result) => {
        // this._initKeyboardListeners();
        this._dialogRef = undefined;
      });
  }

  public exit() {
    this._router.navigate([
      "analyze",
      "cases",
      this._route.snapshot.data["case"].id,
    ]);
  }

  public toggleViewerMode(mode: TOsdActiveAction) {
    this._viewerService.toggleViewerMode(mode);
  }

  public toggleAsset() {
    this._showMobileAnalysisPanelB$.next(true);
  }

  public closeMobileAnalysisPanel() {
    this._showMobileAnalysisPanelB$.next(false);
  }

  public toggleMultiModelSelection(): void {
    this.store.dispatch(toggleMultiSelect());
  }

  public togglePinnedModels(): void {
    this.store.dispatch(togglePinnedOptionsOnly());
  }

  public async toggleFavouriteAnalysis() {
    const analysisState = this._currentAnalysisState;
    analysisState.favAssets = analysisState?.favAssets
      ? analysisState?.favAssets
      : [];
    const favAnalysis = analysisState?.favAssets?.includes(
      this._selectedAsset.id
    );
    favAnalysis
      ? analysisState.favAssets.splice(
          analysisState.favAssets.indexOf(this._selectedAsset.id),
          1
        )
      : analysisState.favAssets.push(this._selectedAsset.id);
    this.store.dispatch(
      setFavAnalysis({ assetId: this._selectedAsset.id, analysisState })
    );
    await this._sampleAnalysisService.saveAnalysisState(analysisState);
  }

  public async copyUserAnalysis(
    analysisState: AnalysisState,
    $event: Event
  ): Promise<void> {
    const dialogReview = this.noShowCopyDialog
      ? of(true)
      : this._dialog
          .open(this.reviewAnalysisDialogTpl, {
            data: { analysisState },
            maxWidth: "55%",
          })
          .afterClosed();
    dialogReview.pipe(takeUntil(this._destroy$)).subscribe(async (_accept) => {
      if (_accept) {
        this._logger.info(
          `Copy Analysis of: ${
            analysisState.user?.displayName ?? "IA"
          } to review`
        );
        this.store.dispatch(
          copyAnalysis({ authUser: this._authService.currentUser.toPointer() })
        );
        this.switchToReviewAnalysis();
        this.toggleViewerMode(TOsdActiveAction.drawing);
      } else {
        $event.preventDefault();
      }
    });
  }

  public switchToReviewAnalysis() {
    this._router.navigate([], {
      queryParams: { historyId: undefined },
      queryParamsHandling: "merge",
    });
  }

  public switchActiveAnalysis(analysisState: AnalysisState) {
    this._logger.info(`Updating Analysis State id: ${analysisState.id}`);
    this._router.navigate([], {
      queryParams: { historyId: analysisState.id },
      queryParamsHandling: "merge",
    });
  }

  public closeSwitchAnalystPanel(isAI: boolean, hasUnsavedData: boolean) {
    if (hasUnsavedData && this._currentMode === Mode.REVIEW) {
      this._dialog
        .open(UnsavedChangesDialogComponent, { data: { username: undefined } })
        .afterClosed()
        .subscribe((discard) => {
          if (!discard) return;
          this.store.dispatch(switchMode({ mode: Mode.ANALYSIS })); // deactivate review mode
          this.switchingAnalystPanelVisible = false;
          this.switchToReviewAnalysis();
          if (isAI) this.analysisTools.togglePanel("analyst");
          this.toggleViewerMode(TOsdActiveAction.drawing);
        });
    } else {
      this.store.dispatch(switchMode({ mode: Mode.ANALYSIS }));
      this.switchingAnalystPanelVisible = false;
      this.switchToReviewAnalysis();
      if (isAI) this.analysisTools.togglePanel("analyst");
      this.toggleViewerMode(TOsdActiveAction.drawing);
    }
  }

  public goToNextCase(caseId: string, sampleId: string) {
    this._router.navigate(["analyze", "cases", caseId, "samples", sampleId]);
  }

  public goToPreviousCase(caseId: string, sampleId: string) {
    this._router.navigate(["analyze", "cases", caseId, "samples", sampleId]);
  }

  public goToPreviousSample(sampleId: string) {
    const actualSample = this.getSample();
    this._router.navigate([
      "analyze",
      "cases",
      actualSample.case.id,
      "samples",
      sampleId,
    ]);
  }

  public goToNextSample(sampleId: string) {
    const actualSample = this.getSample();
    this._router.navigate([
      "analyze",
      "cases",
      actualSample.case.id,
      "samples",
      sampleId,
    ]);
  }

  toggleAnalysisMode(mode) {
    if (mode === "mosaic") this.toggleViewerMode(undefined);
    if (mode === "normal") this.toggleViewerMode(TOsdActiveAction.drawing);
    this.store.dispatch(toogleAnalysisMode({ mode }));
  }

  //#region AI experiments
  public updateOrder(event: MatRadioChange) {
    switch (event.value) {
      case "default":
        // this._sampleAnalysisService.changeAssetsOrder({ compareFcn: (item1, item2) => item1.assetIndex - item2.assetIndex });
        this._snackBar.open("Restored original assets order", null, {
          duration: 800,
        });
        break;
      case "ai":
        this._prioritizeAI();
        break;
    }
  }
  private async _prioritizeAI() {
    this._aiService
      .prioritizeSample(this._sample)
      .pipe(takeUntil(this._destroy$))
      .subscribe({
        next: (priorityResults) => {
          if (!priorityResults.order) return;
          // this._sampleAnalysisService.changeAssetsOrder({ order: priorityResults.order });
        },
        error: null,
        complete: () =>
          this._snackBar.open("Finished prioritizing sample assets", null, {
            duration: 800,
          }),
      });
  }
  //#endregion

  trackById(index: number, roi: ROI): string {
    return roi.id;
  }

  videoBarResized() {
    const wrapperElement = this.scrollableWrapper?.nativeElement;

    if (wrapperElement) {
      this.renderer.setStyle(wrapperElement, "overflow-y", "auto");
    }
  }
}
