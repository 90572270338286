import { Injectable } from "@angular/core";
import { TRoiSelectionType } from "@telespot/sdk";
import { BehaviorSubject, Subject } from "rxjs";
import { distinctUntilChanged, shareReplay, tap } from "rxjs/operators";

import { TOsdActiveAction } from "../../models/osd-active-action";

import { ImageFilter, ImageFilterType } from "../../models/viewer-config";

@Injectable({
  providedIn: "root",
})
export class ViewerService {
  constructor() {
    this._selectedPresetSubject.next(null);
  }

  private _filtersChangedSubject = new Subject<void>();
  private _selectedPresetSubject = new Subject<number>();
  private _currentViewerMode: TOsdActiveAction;
  private _activeViewerMode$ = new BehaviorSubject<TOsdActiveAction>(
    TOsdActiveAction.drawing
  );
  private _activeDrawingMode$ = new BehaviorSubject<TRoiSelectionType>(
    TRoiSelectionType.center
  );
  private _page$ = new BehaviorSubject<number>(0);

  public filtersChanged$ = this._filtersChangedSubject.asObservable();
  public selectedPreset$ = this._selectedPresetSubject.asObservable();
  public imageFilters: ImageFilter[] = [
    {
      filter: ImageFilterType.BRIGHTNESS,
      value: 0,
    },
    {
      filter: ImageFilterType.CONTRAST,
      value: 0,
    },
    {
      filter: ImageFilterType.SATURATION,
      value: 0,
    },
    {
      filter: ImageFilterType.NEGATIVE,
      value: 0,
    },
  ];

  public get currentViewerMode() {
    return this._currentViewerMode;
  }
  public readonly activeViewerMode$ = this._activeViewerMode$
    .asObservable()
    .pipe(
      tap((mode) => (this._currentViewerMode = mode)),
      shareReplay({ bufferSize: 1, refCount: true })
    );
  public activeDrawingMode$ = this._activeDrawingMode$
    .asObservable()
    .pipe(distinctUntilChanged(), shareReplay(1));
  public readonly page$ = this._page$
    .asObservable()
    .pipe(distinctUntilChanged());

  public toggleDrawingMode(mode: TRoiSelectionType) {
    this._activeDrawingMode$.next(mode);
  }

  public toggleViewerMode(mode: TOsdActiveAction) {
    this._activeViewerMode$.next(mode);
  }

  public setPage(index: number) {
    this._page$.next(index);
  }

  public getImageFilters(): ImageFilter[] {
    return this.imageFilters;
  }

  public updateImageFilter(event: ImageFilter): void {
    const filterUpdated = this.imageFilters.find(
      (f) => f.filter === event.filter
    );
    if (filterUpdated) {
      this.setSelectedPreset(null);
      filterUpdated.value = event.value;
      this._filtersChangedSubject.next();
    }
  }

  public updateImageFiltersBulk(event: ImageFilter[]): void {
    event.forEach((eventFilter) => {
      const filterType = eventFilter.filter;
      const imageFilter = this.imageFilters.find(
        (filter) => filter.filter === filterType
      );
      if (imageFilter) {
        imageFilter.value = eventFilter.value;
      }
    });
    this._filtersChangedSubject.next();
  }

  public setSelectedPreset(event: number | null) {
    this._selectedPresetSubject.next(event);
  }

  public resetImageFilters() {
    this.imageFilters.forEach((filter) => {
      filter.value = 0;
    });
    this.setSelectedPreset(null);
    this._filtersChangedSubject.next();
  }
}
