import Parse from "parse";
import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { Store } from "@ngrx/store";
import { ChatService } from "../../services/chat/chat.service";
import { catchError, map, mergeMap, withLatestFrom } from "rxjs/operators";
import * as ChatActions from "./chat.actions";
import { from, of } from "rxjs";
import {
  selectActiveAssistant,
  selectMessagesFromActiveChat,
} from "./chat.selectors";
import { ChatMapper } from "../../services/chat/chat.mapper";
import { mappedLabelCount } from "@telespot/analysis-refactor/data-access";
import { CaseTopology } from "@telespot/infrastructure/parse";
import { Case } from "@telespot/sdk";

@Injectable()
export class ChatEffects {
  setChatContext$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ChatActions.setChatContext),
      mergeMap(({ caseId }) => [
        ChatActions.clearChatState(),
        ChatActions.loadAssistants({ caseId }),
      ]),
      catchError((error) =>
        of(
          ChatActions.chatActionError({
            error: `[setChatContext]: ${error.message}`,
          })
        )
      )
    )
  );

  loadAssistants$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ChatActions.loadAssistants),
      mergeMap(({ caseId }) => {
        return caseId ? from(this.getCase(caseId)) : of(null);
      }),
      mergeMap((currCase) => {
        const caseContext = currCase?.data;
        return this.chatService.fetchAssistants(currCase?.caseType?.id).pipe(
          map((assistants) =>
            ChatMapper.toStateAssistant(assistants, caseContext)
          ),
          map((assistants) => ChatActions.assistantsLoaded({ assistants })),
          catchError((error) =>
            of(
              ChatActions.chatActionError({
                error: `[loadAssistants]: ${error.message}`,
              })
            )
          )
        );
      })
    )
  );

  sendMessage$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ChatActions.sendMessage),
      withLatestFrom(
        this.store$.select(selectActiveAssistant),
        this.store$.select(selectMessagesFromActiveChat),
        this.store$.select(mappedLabelCount)
      ),
      mergeMap(([{ content, timestamp }, assistant, oldMessages, counter]) => {
        return from(
          this.chatService.sendMessage(
            { content, timestamp },
            assistant,
            oldMessages,
            counter
          )
        ).pipe(
          map((response) => {
            const mappedResponse = ChatMapper.fromAPIResponse(response);
            return ChatActions.receivedMessages({ messages: mappedResponse });
          }),
          catchError((error) => {
            return of(
              ChatActions.chatActionError({
                error: `[sendMessage]: ${error.message}`,
              })
            );
          })
        );
      })
    )
  );
  constructor(
    private actions$: Actions,
    private store$: Store,
    private chatService: ChatService
  ) {}

  private async getCase(caseId: string) {
    const query = new Parse.Query(CaseTopology.TABLE);
    return (await query.get(caseId)) as Case;
  }
}
