import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from "@angular/core";

import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { Store } from "@ngrx/store";
import {
  setChatContext,
  selectAssistantsList,
  selectActiveAssistant,
  startChatWithAssistant,
  sendMessage,
  setCurrentUser,
  selectSendingMessage,
  selectMessagesFromActiveChat,
  IMessage,
  resetChat,
  selectLoading,
  IAssistant,
  selectContext,
  Context,
} from "@telespot/chat/data-access";
import { User } from "@telespot/sdk";

import { AuthService } from "@telespot/web-core";
import { Router } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { currentCaseId } from "@telespot/analysis-refactor/data-access";

@Component({
  selector: "ts-chat-box",
  templateUrl: "./chat-box.component.html",
  styleUrls: ["./chat-box.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ChatBoxComponent implements OnDestroy, OnInit {
  @ViewChild("chatContainer") private chatContainer!: ElementRef;
  @Input() showChatBox: boolean;
  @Output() showChatBoxChanged: EventEmitter<boolean> =
    new EventEmitter<boolean>();

  public readonly assistants$ = this._store.select(selectAssistantsList);
  public readonly activeAssistant$ = this._store.select(selectActiveAssistant);
  public readonly currentCaseId$ = this._store.select(currentCaseId);
  public readonly sending$ = this._store.select(selectSendingMessage);
  public readonly currentUser$ = this.authService.currentUser$;
  public readonly messages$ = this._store.select(selectMessagesFromActiveChat);
  public readonly loading$ = this._store.select(selectLoading);
  public readonly context$ = this._store.select(selectContext);
  public readonly currentOrganization$ = this.authService.currentOrganization$;

  public activeAssistant: IAssistant;
  public chatVisible = false;
  public isLoggedIn = false;
  public isChatMinimized = false;
  public llmFeatureEnabled = false;

  public newMessage = "";

  public messages: IMessage[] = [];
  public currentContext: Context;

  private _destroy$ = new Subject<void>();
  private _startX: number;
  private _startY: number;
  private resizing = false;

  constructor(
    private _store: Store,
    private authService: AuthService,
    private router: Router,
    private languageService: TranslateService,
    private changeDetectorRef: ChangeDetectorRef
  ) {
    this._store.dispatch(
      setCurrentUser({ user: this.authService.currentUser })
    );
    this.currentCaseId$.pipe(takeUntil(this._destroy$)).subscribe((caseId) => {
      this._store.dispatch(setChatContext({ caseId }));
    });

    this.activeAssistant$
      .pipe(takeUntil(this._destroy$))
      .subscribe((activeAssistant) => {
        this.activeAssistant = activeAssistant;
      });

    this.currentUser$.pipe(takeUntil(this._destroy$)).subscribe((user) => {
      this.isLoggedIn = !!user;
    });

    this.messages$.pipe(takeUntil(this._destroy$)).subscribe((messages) => {
      this.messages = messages;
    });

    this.context$.pipe(takeUntil(this._destroy$)).subscribe((ctx) => {
      this.currentContext = ctx;
    });

    this.router.events.subscribe((event) => {
      const currentRoute = this.router.url;
      this.chatVisible =
        this.llmFeatureEnabled && currentRoute.includes("login") === false;
      //force re-rendeer
      this.changeDetectorRef.detectChanges();
    });
  }

  public closeChat() {
    this.showChatBoxChanged.emit(false);
  }
  public reset() {
    this._store.dispatch(resetChat());
  }

  public selectAssistant(assistantId: string) {
    this._store.dispatch(startChatWithAssistant({ assistantId }));
  }

  public sendMessage() {
    this._store.dispatch(
      sendMessage({ content: this.newMessage, timestamp: Date.now() })
    );
    this.newMessage = "";
  }

  ngOnInit(): void {
    this.currentOrganization$
      .pipe(takeUntil(this._destroy$))
      .subscribe((organization) => {
        this.llmFeatureEnabled = organization.license.features?.llm_chat
          ? true
          : false;
        this.chatVisible = this.llmFeatureEnabled;
        //force re-rendeer
        this.changeDetectorRef.detectChanges();
      });
  }

  ngOnDestroy(): void {
    this._destroy$.next();
  }

  public getSenderRole(sender: User | IAssistant) {
    return sender instanceof User;
  }

  startResize(event: MouseEvent): void {
    if (!this.isChatMinimized) {
      this._startX = event.clientX;
      this._startY = event.clientY;
      this.resizing = true;
      document.body.style.cursor = "col-resize";
      document.addEventListener("mousemove", this.resize.bind(this));
      document.addEventListener("mouseup", this.stopResize.bind(this));
    }
  }

  resize(event: MouseEvent): void {
    if (!this.resizing || this.isChatMinimized) {
      return;
    }

    const deltaX = event.clientX - this._startX;
    const deltaY = event.clientY - this._startY;
    const newWidth = Math.max(300 - deltaX, 300);
    const newHeight = Math.max(450 - deltaY, 450);

    const cardContainer = document.querySelector(
      ".cardContainer"
    ) as HTMLElement;
    cardContainer.style.width = newWidth + "px";
    cardContainer.style.height = newHeight + "px";
  }

  stopResize(): void {
    if (this.resizing) {
      this.resizing = false;
      document.body.style.cursor = "auto";
      document.removeEventListener("mousemove", this.resize.bind(this));
      document.removeEventListener("mouseup", this.stopResize.bind(this));
    }
  }

  getAssistantDescription(description: any) {
    return description[this.languageService.currentLang || "en"];
  }

  toggleChat(): void {
    this.isChatMinimized = !this.isChatMinimized;
    if (this.isChatMinimized) {
      const cardContainer = document.querySelector(
        ".cardContainer"
      ) as HTMLElement;
      cardContainer.style.height = "auto";
      cardContainer.style.overflow = "hidden";
    } else {
      const cardContainer = document.querySelector(
        ".cardContainer"
      ) as HTMLElement;
      cardContainer.style.height = "450px";
      cardContainer.style.overflow = "auto";
    }
  }
}
